// export default Todayspricevolume;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { EmailShareButton, TwitterShareButton } from "react-share";
import ConfirmationDialog_add from "../ConfirmationDialog_add.js";
import ConfirmationDialog_minus from "../ConfirmationDialog_minus.js";
import EditDialog_minus from "./EditDialog_minus.js";
import EditDialog_add from "./EditDialog_add.js";
const Todayspricevolume = ({ initialUserData }) => {
  const [stocks, setStocks] = useState([]);
  const [currentPrices, setCurrentPrices] = useState({}); // To store current prices
  const [currentVolume, setCurrentVolume] = useState({}); // To store current prices
  const [updateData, setUpdateData] = useState(null);
  const [editDialogOpen_add, setEditDialogOpen_add] = useState(false);
  const [editDialogOpen_minus, setEditDialogOpen_minus] = useState(false);

  useEffect(() => {
    const fetchPublicUserData = async () => {
      try {
        const username = localStorage.getItem('unm');
        const res = await axios.get(Base_URL + "/portfolio/stocks", {
          params: { username: username }
        });
        setStocks(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };

    fetchPublicUserData();
  }, []);

  useEffect(() => {
    if (stocks.length > 0) {
      fetchStockData();
    }
  }, [stocks]);

  const localLogoImagePath = "/images/companylogos/";

  const handleStockModal_minus = (stock) => {
    setUpdateData(stock);
    setEditDialogOpen_minus(true);
  };

 

  const closeEditDialog_minus = () => {
    setEditDialogOpen_minus(false);
    setUpdateData(null);
  };

  const fetchStockData = () => {
    const symbols = localStorage.getItem('symbolstring');
    const url = Base_URL_TV+`/tvapi?symbols=[${symbols}]`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log("Received data:", data);
        // Update state with fetched data
        const prices = data.reduce((acc, stock) => {
          if (!stock.error) {
            acc[stock.symbol] = stock.close_price;
               }
          return acc;
        }, {});
        setCurrentPrices(prices);
        const volumes = data.reduce((acc, stock) => {
          if (!stock.error) {
            acc[stock.symbol] = stock.volume;
       
               }
          return acc;
        }, {});
        setCurrentVolume(volumes);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };


  return (
    <>
      <p className="text-white fs-6 fw-bold font-family-Montserrat">
      TODAY’S PRICE & VOLUME INFORMATION
      </p>
      <div className="row h-25 w-100">
            <div className="text-white text-opacity-50 fs-6 fw-normal font-family-Montserrat col-10 m-0 px-3 py-2 " style={{ backgroundColor: 'black', width: '100%', height: '300px', overflow: 'auto' }}>
                     <table className=" bg-black text-white pl-5 table" class="table-dark mytable" style={{ backgroundColor: 'black' ,width:"100%",padding: "auto" ,margin: "auto"}}>
        
            <thead>
              <tr className="border-bottom  border-primary-subtle border-opacity-25">
                <th align="center"></th>
                <th>Stock</th>
                <th class="text-center">Daily Close Price</th>
                <th class="text-center">Daily Volume </th>
                 </tr>
            </thead>
            <tbody>
              {stocks.map(stock => (
                <tr key={stock.ordid} className="border-bottom  border-secondary border-opacity-25">
                  <td className="w-1 p-0">
                    <img
                      src={stock.ctick ? `${localLogoImagePath}/${stock.ctick}.png` : '/images/companylogos/default-logo.png'}
                      width="30px" height="auto"
                      alt={stock.cname}
                    />
                  </td>
                  <td className="text-nowrap">{stock.cname}<br />{stock.ctick}</td>
                  <td align="center">{currentPrices[stock.ctick] !== undefined ? currentPrices[stock.ctick].toFixed(2) : 'N/A'}</td>
                  <td align="center">{currentVolume[stock.ctick] !== undefined ? currentVolume[stock.ctick] : 'N/A'}</td>
                   </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
 
    </>
  );
};

export default Todayspricevolume;
