import * as React from 'react';
import { useNavigate } from "react-router-dom";

function LandingSectionThree() {
    const navigate = useNavigate();
    function navigateTo(path) {
        navigate(path);
      }
    function handleExplore(evt) {
        navigateTo("/explore");
      }
      function handleExplorefirstedge(evt) {
        navigateTo("/firstedge");
      }
    return (
        <>
            <div className="bg-black ">
                <div className='container p-5' >
                    <div className="row text-white text-center">
                        <div className="col-lg-6 d-flex justify-content-center align-items-center">
                        <a href="#" target="_self" onClick={handleExplore} style={{ textDecoration: 'none' }}> 
                            <div className="card text-center text-white m-3 col-lg-8 p-3 rounded-4" style={{ backgroundColor: '#FFFFFF15' }} >
                                <div className="card-image">
                                    <img src="/images/eye-bold.svg" alt="CSE" className="img-fluid" />
                                </div>
                                <div className="card-body">
                                <div id="component" class="container-fluid py-3">
    <p class="text-center  m-0 px-3 py-2">
        <span class="text-success-subtle fs-4 font-family-Montserrat fw-semibold">
        Explore our&nbsp;
        </span>
        <span class="text-success-subtle fs-4 font-family-Montserrat">
        TrendRunner
        </span>
    </p>
</div>
<div id="component" class="container-fluid py-3">
    <p class="text-start  m-0 px-3 py-2">
        <span class="text-white fs-6 fw-normal font-family-Montserrat">
        Welcome to &nbsp;
        </span>
        <span class="text-white fs-6 fw-bold font-family-Montserrat">
        TrendRunner,
        </span>
        <span class="text-white fs-6 fw-normal font-family-Montserrat">
        where cutting-edge analytics empower your portfolio decisions&nbsp;
        </span>
        <span class="text-white fs-6 fw-bold font-family-Montserrat">
        with Market trends and insights.
        </span>
    </p>
</div>
                                    <div className="pt-5 pl-3 text-white d-flex align-items-center mt-auto">
                                        <span>Explore Features</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width={24} height={24}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center align-items-center" >
                        <a href="#" target="_self" onClick={handleExplorefirstedge} style={{ textDecoration: 'none' }}> 
                            <div className="card text-center text-white m-3 col-lg-8 p-3  rounded-4 " style={{ backgroundColor: '#FFFFFF15' }}>
                                <div className="card-image">
                                    <img src="/images/power-bold.svg" alt="CSE" className="img-fluid" />
                                </div>
                                <div className="card-body">
                                <p class="text-center  m-0 px-3 py-2">
        <span class="text-success-subtle fs-4 font-family-Montserrat fw-semibold">
        Unleash the&nbsp;
        </span>
        <span class="text-success-subtle fs-4 font-family-Montserrat">
        Firstedge
        </span>
    </p>   
    
    <div id="component" class="container-fluid py-3">
    <p class="text-start  m-0 px-3 py-2">
        <span class="text-white fs-6 fw-normal font-family-Montserrat">
        Discover the future of proactive investing with&nbsp;
        </span>
        <span class="text-white fs-6 fw-bold font-family-Montserrat">
        FirstEdge Alerts,
        </span>
        <span class="text-white fs-6 fw-normal font-family-Montserrat">
        delivering instant notifications to keep you ahead of the market curve.
        </span>
    </p>
</div>
                                    <div className="pt-5 pl-3 text-white d-flex align-items-center">
                                        <span>Take a Look</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" width={24} height={24}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LandingSectionThree;


