// export default EditDialog;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { Close, DateRangeSharp } from '@material-ui/icons';
import axios from "axios";
import { Base_URL } from '../../Components/BaseURL.js';
import TextField from "@material-ui/core/TextField";
import "bootstrap-icons/font/bootstrap-icons.css";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
const alertcreationModal = ({ open, onClose, data }) => {
  console.log('alertcreationModaldata---'+JSON.stringify(data));
  console.log('tick: ' + data.tick);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  console.log('csemcat_name: ' + data.csemcat_name);
// Handle changes in form inputs
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(Base_URL + `/portfolio/updatestock`, '');
      toast.success("Stock Data Successfully updated.");
       onClose(); // Close the modal
       } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      contentStyle={{width: "100%", maxWidth: "none"}}
    >
      <div style={{width: 600}}>
    <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">Create Alert</Typography>
        <div className="col-auto font-family-Montserrat text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}s>
        <Close/>
        </IconButton></div>
      </DialogTitle>
      <DialogContent dividers>

        <ToastContainer />
        <form onSubmit={handleSubmit} class="form-inline">

        <div id="component" className="container-fluid py-3 w-100">

 <div className="row row-cols-12 pt-3">
  <div className="col text-end font-family-Montserrat">
    Alert name
    </div>
    <div className="col text-start col-8">
    <input
              type="text"  
              className="form-control w-100 text-black bg-white fw-light"
              id="InputLastName"
              name="InputLastName"
              value=""
              placeholder="Last Name"
             
            />
    </div>
  </div>
  <div className="row row-cols-3 pt-3">
  <div className="col text-end">
  Price Threshold (LKR)

    </div>
    <div className="col text-start col-8">
    <input
              type="text"  
              className="form-control w-100 bg-white text-black fw-light"
              id="InputLastName"
              name="InputLastName"
              value=""
              placeholder="Last Name"
             
            />
    </div>
  </div>
  <div className="row row-cols-3 pt-3">
  <div className="col text-end">
  Media of Alerts
    </div>
    <div className="col text-start col-8">
    <select
                className="form-select text-white bg-black fw-light"
                // onChange={(e) => handleCountrySelect(e.target.value)}
                name="InputCountry"
                value=""
              >
                <option key="1" value="SMS">SMS</option>
                <option key="2" value="email">E-mail</option>
                <option key="3" value="SMS">Both SMS and E-mail</option>
              </select>
    </div>
  </div>
  <div className="row row-cols-3 pt-3">
  <div className="col text-end">
  Enable/ Disable Alert
    </div>
    <div className="col text-start col-8">
    <FormControlLabel
          value="top"
          control={<Switch color="primary" />}
          label="Top"
          labelPlacement="top"
        />
    </div>
  </div>
  <div className="row row-cols-3 pt-3">
  <div className="col text-end">
  Enable/ Disable Daily Alert
    </div>
    <div className="col text-start col-8">
    <input
              type="text"  
              className="form-control w-100 text-white bg-black fw-light"
              id="InputLastName"
              name="InputLastName"
              value=""
              placeholder="Last Name"
             
            />
    </div>
  </div>
</div>
           </form>
      </DialogContent>
      </div>
        </Dialog>
      
           </>
  );
};
export default alertcreationModal;