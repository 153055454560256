
import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import {
  PieChart, Pie, Cell, Tooltip, ResponsiveContainer
} from 'recharts';
import './Portfolio.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import fetchCPrice from './fetchCurrentPrice';
import html2canvas from 'html2canvas';
import { chartColors } from "./colors.js";
import { TwitterShareButton } from "react-share";
import domtoimage from "dom-to-image";
import { FadeLoader } from 'react-spinners';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import moment from 'moment';
import { calcDate } from '../calcDate'; 
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import fetch30CPrice from './fetchClosePrice30days.js';
import Pmenu from './PortfolioPerformance_menu.js';
const PortfolioPerformanceLMonth = ({ initialUserData }) => {
  const [stocks, setStocks] = useState([]);
  const [menudata, setMenudata] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalpfsize, setTotalpfsize] = useState(0);
  const [totalqty, setTotalqty] = useState(0);
  const [Tots, setTots] = useState([]);
  const navigate = useNavigate();
  const [pfcompStrings, setPfcompStrings] = useState([]);
  const [gainStrings, setGainStrings] = useState([]);
  const chartRef = useRef();
  const backgColor = chartColors;
  const [totalgain, setTotalGain] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState(null);
  const crdate = localStorage.getItem('crdate');
  const today = new Date();

  // Format dates for calcDate function
  const formattedToday = moment(today).format('YYYY-MM-DD');
  const formattedCrDate = moment(crdate).format('YYYY-MM-DD');
  console.log(formattedToday+'----'+formattedCrDate);
  const dateDifference = calcDate(formattedToday, formattedCrDate);
 // console.log(JSON.stringify(dateDifference.total_days)+'----nofm----------', JSON.stringify(dateDifference.result));
  useEffect(() => {
    const fetchPublicUserData = async () => {
      setIsLoading(true);
      try {
        const username = localStorage.getItem('unm');
        const res = await axios.get(Base_URL + "/portfolio/stocks", {
          params: { username: username }
        });
        setStocks(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };

    fetchPublicUserData();
  }, []);

  const symbols = stocks.map(stock => stock.ctick);
  localStorage.setItem('symbolstring', symbols);

  // const calculateTotal = useCallback(async () => {
  //   try {
  //     let totalValue = 0;
  //     let totalPFSizeValue = 0;
  //     let calculatedTots = [];
  //     let newGainStrings = [];
  //     let newPfcompStrings = [];
  //     let newlgndgainpercentage = [];
  //     for (let i = 0; i < stocks.length; i++) {
  //       const stock = stocks[i];
  //       const label = stock.cname;
  //       const quantity = stock.csemco_quantity;
  //       const avgPrice = stock.avgprice;
  //       const tick = stock.ctick;

  //       const matchingData = await fetchCPrice(tick);

  //       if (matchingData) {
  //         const lcpap = parseFloat(matchingData) - parseFloat(avgPrice);
  //         const gain = lcpap * quantity;
  //         const gainString = gain.toFixed(2);
  //         totalValue += Math.abs(gain);
  //         calculatedTots.push(gain.toFixed(2));
  //         newGainStrings.push(Math.round(gainString));
  //         const pfcomp = parseFloat(avgPrice) * quantity;
  //         const lgndgainpercentage = ((lcpap * 100) / parseFloat(avgPrice)).toFixed(2);
  //         newlgndgainpercentage.push(lgndgainpercentage);
  //         const pfcompString = Math.round(pfcomp);
  //         totalPFSizeValue += pfcomp;
  //         newPfcompStrings.push(pfcompString);
  //       }
  //     }
  //     setTotalpfsize(totalPFSizeValue);
  //     setTotalGain(totalPFSizeValue);
  //     setTotal(totalValue);
  //     setTots(newlgndgainpercentage);
  //     setPfcompStrings(newPfcompStrings);
  //   } catch (error) {
  //     console.error('Error calculating total:', error);
  //   }
  // }, [stocks, fetchCPrice]);

    const calculateTotal = useCallback(async () => {
    try {
      let totalValue = 0;
      let tqty = 0;
      let totalPFSizeValue = 0;
      let labelsSet = new Set();
      let calculatedTots = [];
      let newGainStrings = [];
      let newPfcompStrings = [];
      let dnutpcnt = 0;
      let newlgndgainpercentage = [];
      for (let i = 0; i < stocks.length; i++) {
        const stock = stocks[i];
        const label = stock.cname;
  
        if (!labelsSet.has(label)) {
          labelsSet.add(label);
          const quantity = stock.csemco_quantity;
          const avgPrice = stock.avgprice;
          const tick = stock.ctick;
  
          // Fetch current price data
          const matchingData = await fetch30CPrice(tick);
          // console.log('---matchingData-----', matchingData);
  
          if (matchingData) {
            const lcpap = parseFloat(matchingData) - parseFloat(avgPrice);
            const gain = lcpap * quantity;
            const gainString = gain.toFixed(2);
            totalValue += Math.abs(gain);
            calculatedTots.push(gain.toFixed(2));
            newGainStrings.push(Math.round(gainString));
            const pfcomp = parseFloat(avgPrice) * quantity;
            const lgndgainpercentage = ((lcpap * 100) / parseFloat(avgPrice)).toFixed(2);
            newlgndgainpercentage.push(lgndgainpercentage)
            const pfcompString = Math.round(pfcomp);
            tqty += quantity;
            totalPFSizeValue += pfcomp;
            newPfcompStrings.push(pfcompString);
          }
        }
      }
      setTotalpfsize(totalPFSizeValue);
      setTotalGain(totalPFSizeValue);
      setTotal(totalValue);   
      setTots(newlgndgainpercentage);
      setPfcompStrings(newPfcompStrings);
       // if (totalpfsize > 0){
      //   dnutpcnt = (((parseInt(total) * 100)/ parseInt(pfcompStrings))).toFixed(2);
      //  } else{
      //    dnutpcnt =0;
      //  }
       setTotalqty(dnutpcnt);
  
    } catch (error) {
      console.error('Error calculating total:', error);
      // Handle error as per your application's needs
    }
  }, [stocks, fetch30CPrice]);

  useEffect(() => {
    const fetchData = async () => {
      await calculateTotal();
    };
    fetchData();
  }, [stocks]);

  const downloadChartAsImage = async () => {
    const datapdf = document.getElementById('Doughnutrefid');
    const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    try {
      html2canvas(datapdf, { backgroundColor: 'black', allowTaint: true, useCORS: true }).then((canvas) => {
        var link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = `portfolio_gainsnapshot_${currentDate}.png`;
        link.click();
      });
    } catch (error) {
      console.error('Error downloading the chart:', error);
      toast.error('Failed to download chart');
    }
  };

  function handleSelect(evt) {
    setMenudata(evt);
  }

  function navigateTo(path) {
    navigate(path);
  }

  function handleSelect_gain(evt) {
    navigateTo("/portfolio_gain");
  }

  function handleSelect_apd(evt) {
    navigateTo("/portfolio_apd");
  }

  function handleSelect_gs_t(evt) {
    navigateTo("/portfolio/today");
  }

  function handleSelect_gs_lm(evt) {
    navigateTo("/portfolio/last-month");
  }

  function handleSelect_gs_ltm(evt) {
    navigateTo("/portfolio/last-three-months");
  }

  const handleShare = () => {
    let node = document.getElementById('Doughnutrefid');
    domtoimage.toPng(node)
      .then(dataUrl => {
        axios.post("http://127.0.0.1:3000/upload/imagetotweet", { dataUrl: dataUrl })
          .then(res => {
            const url = res.data.imageUrl;
            const twitterURL = `https://twitter.com/share?url=${url}&text=Check%20out%20this%20image&hashtags=yourHashtags`;
            window.open(twitterURL, "twitter");
          })
          .catch(err => console.log(err, "Error trying to tweet"));
      })
      .catch(err => console.log(err));
  };

  const chartData = stocks.map((stock, index) => ({
    name: stock.cname,
    value: stock.csemco_quantity * stock.avgprice,
    tick: stock.ctick,
    color: backgColor[index]
  }));

  useEffect(() => {
    if (totalpfsize !== 0 && totalgain !== 0 && total !== 0 && Tots.length > 0 && pfcompStrings.length > 0) {
      setIsLoading(false);
    }
  }, [totalpfsize, totalgain, total, Tots, pfcompStrings]);

  const open = Boolean(anchorEl);
  const nestedOpen = Boolean(nestedAnchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNestedClick = (event) => {
    setNestedAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNestedAnchorEl(null);
  };

  return (
    <>
      <div className="container">
           <div className="row">
          <div className="col-md-12">
          <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="white" href="/">
          Home</Link>
        <Link underline="hover" color="white" href="/portfolio">
        Portfolio Analytics</Link>
         <Link underline="hover" color="white" href="/portfolio">
         Gain Snapshot</Link>
        <Typography color="white">Last Month</Typography>
      </Breadcrumbs>
          </div>
        </div>
<Pmenu label="last_month"/>
        <div className="row" id="Doughnutrefid">
          <div className="col-md-8 p-0">
            <div ref={chartRef} style={{ backgroundColor: 'rgba(255, 255, 255, 0.07)', height: "60vh", position: "relative", marginBottom: "0%", padding: "0%" }} className="container d-flex align-items-center justify-content-center p-0 text-white font-family-Montserrat fontSize='12px'">
              {isLoading && (
                <div className="spinner-container">
                  <FadeLoader color="#ffffff" loading={isLoading} size={50} />
                </div>
              )}
              <ResponsiveContainer width="100%" height={400}>
                {!isLoading && (
                  <PieChart>
                    <Pie
                      data={chartData}
                      cx="50%"
                      cy="50%"
                      fontSize={"14px"}
                      innerRadius={120}
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, value }) => `${name}: ${value} LKR`}
                    >
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={backgColor[index % backgColor.length]} />
                      ))}
                    </Pie>
                    <Tooltip wrapperStyle={{ fontFamily: 'Montserrat', fontSize: '12px' }} cornerRadius={15} />
                    <text
                      x={450}
                      y={160}
                      fontSize="25px"
                      textAnchor="middle"
                      style={{ fontWeight: "bold" }}
                      fill="white"
                      dominantBaseline="middle"
                    >
                      {
                        totalgain !== 0 ?
                          (((parseInt(total) * 100) / parseInt(totalgain)).toFixed(2)) + "%"
                          :
                          "0%"
                      }
                    </text>
                    <text x={448} y={190} textAnchor="middle" fill="white" dominantBaseline="middle">Portfolio Size</text>
                    <text x={448} y={220} textAnchor="middle" fill="white" dominantBaseline="middle">{totalgain.toFixed(2)} LKR</text>
                  </PieChart>
                )}
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-center font-family-Montserrat" style={{ backgroundColor: 'rgba(255, 255, 255, 0.07)' }}>
            <table className="font-family-Montserrat text-white" style={{ fontSize: '14px' }}>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">Stock</th>
                  <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gain</th>
                </tr>
              </thead>
              <tbody>
                {stocks.map((stock, index) => (
                  <tr key={index}>
                    <td width="35px"><button style={{ backgroundColor: backgColor[index], color: backgColor[index], width: '20px', height: '20px' }}></button></td>
                    <td style={{ color: '#ffffff' }}>{stock.cname}</td>
                    <td className="text-end">{Tots[index]} %</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioPerformanceLMonth;
