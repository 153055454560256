import React from 'react';
import './ProfileSettings.css'
import ProfileSettingsHeading from './ProfileSettingsHeading';
import ProfileSettingsPrivate from './ProfileSettingsPrivate';
import ProfileSettingsPublic from './ProfileSettingsPublic';
function ProfileSettings() {
    return (
        <>
  <div className="container">
  <div className="p-5"></div>
  <header>
  <ProfileSettingsHeading /> 
   </header>
  <main className="content">
  <div className="row">
  <div className="col-md-6">
  <ProfileSettingsPrivate/>
  </div>
  <div className="col-md-6">
  <ProfileSettingsPublic/>
  </div>
</div>
  
  </main>
  <footer>

   </footer>
</div>
        </>
  
    );
}

export default ProfileSettings;