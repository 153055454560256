import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    return storedAuth ? JSON.parse(storedAuth) : false;
  });
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    // return storedUser ? JSON.parse(storedUser) : null;

    if (storedUser && storedUser !== 'undefined') {
      return JSON.parse(storedUser)
    }


  });

  const login = (userData) => {
    console.log("Login successful, setting user and isAuthenticated");
    setIsAuthenticated(true);
    setUser(userData);
    localStorage.setItem('isAuthenticated', JSON.stringify(true));
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const logout = () => {
    console.log("Logout successful, clearing user and isAuthenticated");
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
  };

  // useEffect(() => {
  //   const storedAuth = localStorage.getItem('isAuthenticated');
  //   const storedUser = localStorage.getItem('user');
  //   if (storedAuth && storedUser) {
  //     setIsAuthenticated(JSON.parse(storedAuth));
  //     if (storedAuth && storedAuth !== 'undefined') {
  //       setUser(JSON.parse(storedAuth));
  //                                                   }
  //   }
  // }, []);
  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    const storedUser = localStorage.getItem('user');
    if (storedAuth) {
      setIsAuthenticated(JSON.parse(storedAuth));
    }
    if (storedUser && storedUser !== 'undefined') {
      setUser(JSON.parse(storedUser));
    }
  }, []);
  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
