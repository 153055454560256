import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { Base_URL } from '../../Components/BaseURL.js';
import TextField from "@material-ui/core/TextField";
import EditConfirmDialog from "./EditConfirmDialog_minus.js";
import { Close } from '@material-ui/icons';

const EditDialog_minus = ({ open, onClose, stockData, onUpdate }) => {
  const [editDialogOpen_minus, setEditDialogOpen_minus] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [initialFormData, setInitialFormData] = useState(null);
  const [formDataStocks, setFormDataStocks] = useState({
    InputOrdID:"",
    InputStock: "",
    Inputmax:"",
    InputStockName: "",
    InputSQty:"0",
    InputNewQty:"0",
    InputAvgPrice: "",
  });
  const [updateData, setUpdateData] = useState(null);

  useEffect(() => {
    if (stockData) {
        const initialData = {
        InputOrdID:stockData.ordid,
        InputStock: stockData.ctick,
        Inputmax:stockData.csemco_quantity,
        InputStockName: stockData.cname,
        InputSQty:"0",
        InputNewQty:"0",
        InputAvgPrice: stockData.avgprice,
         };
      setFormDataStocks(initialData);
      setInitialFormData(initialData);
    }
  }, [stockData]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataStocks((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const closeEditDialog = () => {
    setEditDialogOpen_minus(false);
    setUpdateData(null);
  };

  const handleStockModal = (formDataStocks) => {
   const jval = Number(JSON.parse(formDataStocks.InputSQty));
    if (jval ===0) {
      toast.info("Please fill Sold Quantity.");
      return;  
    }
    setUpdateData(formDataStocks);
    setEditDialogOpen_minus(true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">Reduce Current Quantity</Typography>
        <div className="col-auto font-family-Montserrat text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}>
        <Close/>
        </IconButton></div>
      </DialogTitle>
      <DialogContent dividers>
        <ToastContainer />
        <form className="form-inline">
          <input type="hidden" name="InputOrdID" id="InputOrdID" value={formDataStocks.InputOrdID}></input>
          <input type="hidden" name="InputStock" id="InputStock" value={formDataStocks.InputStock}></input>
          <input type="hidden" name="Inputmax" id="Inputmax" value={formDataStocks.Inputmax}/>
          <div className="row g-3 align-items-center">
            <div className="form-group row">
              <div className="col-5">
                <p className="text-black fs-6 fw-normal font-family-Montserrat">Stock</p>
              </div>
              <div className="col-7">
                <input 
                  value={formDataStocks.InputStockName}
                  className="form-control  text-dark bg-white fw-light w-100 float-start text-start"
                  id="InputStockName"
                  name="InputStockName"
                  disabled
                /> 
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <p className="text-black fs-6 fw-normal font-family-Montserrat">Sold Quantity</p>
              </div>
              <div className="col-6">
                <input 
                  type="number"
                  name="InputSQty" 
                  id="InputSQty" 
                  className="form-control text-black fw-light w-100 float-start text-end"  
                  min="1"  
                  max={formDataStocks.Inputmax}
                  value={formDataStocks.InputSQty}
                  onChange={handleChange}
                />

              </div>
            </div>  
          </div>
              <div className="form-group row">
              <label className="labelf">
              <h5>Current Quantity - {formDataStocks.Inputmax}</h5>
                </label>
               </div>
              <div className="form-group row">
              <label className="labelf">
              <h5>Current Avg. Price Purchased - {formDataStocks.InputAvgPrice}</h5>
                </label>
               </div>
          <div className="p-4"></div>
          <div className="col-auto text-end">
            <button type="button" className="btn btn-dark border border-white mr-1" onClick={onClose}>
              Close
            </button>  
            <button type="button" className="btn btn-dark border border-white mr-1" onClick={() => handleStockModal(formDataStocks)}>
              Confirm
            </button> 
          </div>
          <EditConfirmDialog
            open={editDialogOpen_minus}
            onClose={closeEditDialog}
            stockData={updateData}
            onUpdate={(updatedStock) => {
              setStocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
            }}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditDialog_minus;
