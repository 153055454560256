import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./page/Layout";
import Home from "./Home";
import Blogs from "./page/Blogs";
import ExploretrendRunner from "./page/landing/Exploretrendrunner";
import Explorefirstedge from "./page/landing/Explorefirstedge";
import Alertmanagement from "./page/alertmanagement/index";
import Contact from "./page/Contact";
import NoPage from "./page/NoPage";
import Login from "./page/login/index";
import UserRegister from "./page/login/userregister";
import AboutUs from './page/about-us/index';
import ProfileSettings from "./page/ProfileSettings/index";
import CounTry from './page/ProfileSettings/couintry';
import { GoogleOAuthProvider } from "@react-oauth/google";
import './index.css'; // Import global CSS here
import { AuthProvider } from "./AuthContext"; // Import AuthProvider
import ProtectedRoute from "./ProtectedRoute"; // Import ProtectedRoute
import Portfolio from './page/portfolio/index';
import PortfolioPerformance from './page/portfolio/PortfolioPerformance';
import PortfolioPerformanceToday from "./page/portfolio/PortfolioPerformanceToday";
import PortfolioPerformanceLMonth from './page/portfolio/PortfolioPerformanceLMonth';
import PortfolioPerformanceLThreeM from './page/portfolio/PortfolioPerformanceLThreeM';
import Portfolio_gain from './page/portfolio/gain/index';
import PortfolioPerformance_gain from './page/portfolio/gain/PortfolioPerformance';
import PortfolioPerformanceToday_gain from "./page/portfolio/gain/PortfolioPerformanceToday";
import PortfolioPerformanceLMonth_gain from './page/portfolio/gain/PortfolioPerformanceLMonth';
import PortfolioPerformanceLThreeM_gain from './page/portfolio/gain/PortfolioPerformanceLThreeM';
import Portfolio_apd from './page/portfolio/apd/index';
import Forgotpw from './page/forgotpw/index';
ReactDOM.createRoot(document.getElementById('root')).render(
  <GoogleOAuthProvider clientId='YOUR_GOOGLE_CLIENT_ID'>
    <AuthProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AuthProvider>
  </GoogleOAuthProvider>
);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="country" element={<CounTry />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="explore" element={<ExploretrendRunner />} />
          <Route path="firstedge" element={<Explorefirstedge />} />
          {/* <Route path="alertmng" element={<Alertmanagement />} /> */}
          <Route path="login" element={<Login />} />
          <Route path="userregister" element={<UserRegister />} />

          <Route path="portfolio" element={
            <ProtectedRoute>
              <Portfolio />
            </ProtectedRoute>
          }>
            <Route index element={<PortfolioPerformance />} />
            <Route path="today" element={<PortfolioPerformanceToday />} />
            <Route path="last-month" element={<PortfolioPerformanceLMonth />} />
            <Route path="last-three-months" element={<PortfolioPerformanceLThreeM />} />
          </Route>

          <Route path="portfolio_gain" element={
            <ProtectedRoute>
              <Portfolio_gain />
            </ProtectedRoute>
          }>
            <Route index element={<PortfolioPerformance_gain />} />
            <Route path="today" element={<PortfolioPerformanceToday_gain />} />
            <Route path="last-month" element={<PortfolioPerformanceLMonth_gain />} />
            <Route path="last-three-months" element={<PortfolioPerformanceLThreeM_gain />} />
          </Route>
       
          <Route path="portfolio_apd" element={
            <ProtectedRoute>
              <Portfolio_apd />
            </ProtectedRoute>
          } />

          <Route path="profilesettings" element={
            <ProtectedRoute>
              <ProfileSettings />
             </ProtectedRoute>
          } />
          <Route path="forget" element={
            <ProtectedRoute>
              <Forgotpw />
            </ProtectedRoute>
          } />

<Route path="alertmng" element={
            <ProtectedRoute>
              <Alertmanagement />
            </ProtectedRoute>
          } />

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
