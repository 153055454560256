// LandingSectionTwo.js

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './CarouselImage';

function LandingSectionTwo() {
    
    return (
        <>
            <div style={{ display: 'block', height: '600px', width: '100%', backgroundColor: 'black' }}>
                <div className='container-fluid'>
                    <Carousel controls={true}>
                        <Carousel.Item>
                            <ExampleCarouselImage className="Carousel_h"
                                CarouselHeader="Real-Time Market Insights" 
                                CarouselContent="Stay ahead of the market with up-to-the-minute data and analysis."
                                src='/images/carousel/carosel-1.png' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <ExampleCarouselImage className="Carousel_h"
                                CarouselHeader="Engage with a Community of Traders"
                                CarouselContent="Connect, discuss, and collaborate with like-minded investors."
                                src='/images/carousel/carosel-2.png' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <ExampleCarouselImage className="Carousel_h"
                                CarouselHeader="Powerful Investment Tools"
                                CarouselContent="Utilize advanced tools to track, analyze, and optimize your portfolio."
                                src='/images/carousel/carosel-3.png' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <ExampleCarouselImage className="Carousel_h"
                                CarouselHeader="Personalized Portfolio Insights"
                                CarouselContent="View and analyze your portfolio with personalized data to better understand and optimize your investments."
                                src='/images/carousel/carosel-4.png' />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            
        </>
    );
}

export default LandingSectionTwo;

