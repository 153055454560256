// export default Home;
import React from 'react';
import Header from './UI-components/layout/common/header/Header';
import Footer from './UI-components/layout/common/footer/Footer';
import Landing from './page/landing';
import './Home.css'; // Add specific CSS for Home component

const Home = () => {
  return (
    <div className="home">
      {/* <Header /> */}
      <main className="landing">
        <Landing />
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;

