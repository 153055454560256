import * as React from 'react';


function SectionFour() {
    return (
        <>
            <div style={{ backgroundColor: "#ffffff15" }}>
                <div>
                    <div className="justify-content-center align-items-start text-white p-3">
                        <div className="text-start justify-content-strat align-items-start ">
                            <h1>Meet Our Team</h1>
                        </div>
                    </div>
                    <div className="row text-black justify-content-center align-items-center p-4">

                        <div className="card text-center col-lg-2 col-md-4 m-3 col-6" style={{ backgroundColor: '#ffffff' }}>
                            <div className="card-image">
                                <img src="images/about-us/team-member.png" alt="CSE" className="img-fluid" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title"><b>Name 1</b></h5>
                                <p>Position 1</p>
                            </div>
                        </div>
                        <div className="card text-center  col-lg-2 col-md-4 m-3 col-6" style={{ backgroundColor: '#FFFFFF' }}>
                            <div className="card-image">
                                <img src="images/about-us/team-member.png" alt="CSE" className="img-fluid" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title"><b>Name 2</b></h5>
                                <p>Position 2</p>
                            </div>
                        </div>

                        <div className="card text-center  col-lg-2 col-md-4 col-6  m-3" style={{ backgroundColor: '#ffffff' }}>
                            <div className="card-image">
                                <img src="images/about-us/team-member.png" alt="CSE" className="img-fluid" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title"><b>Name 3</b></h5>
                                <p>Position 3</p>
                            </div>
                        </div>
                        <div className="card text-center  col-lg-2 col-md-4 col-6 m-3 " style={{ backgroundColor: '#FFFFFF' }}>
                            <div className="card-image">
                                <img src="images/about-us/team-member.png" alt="CSE" className="img-fluid" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title"><b>Name 4</b></h5>
                                <p>Position 4</p>
                            </div>
                        </div>

                        <div className="card text-center  col-lg-2 col-md-4 col-6 m-3 " style={{ backgroundColor: '#FFFFFF' }}>
                            <div className="card-image">
                                <img src="images/about-us/team-member.png" alt="CSE" className="img-fluid" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title"><b>Name 5</b></h5>
                                <p>Position 5</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionFour;