import * as React from 'react';


function SectionTwo() {
    return (
        <>
            <div className="bg-black  ">
                <div style={{   display: 'flex' }}>
                    <div className="d-flex    align-items-strat text-white m-5">
                        <div className='row'>
                            <div className="text-start  col-7    ">
                                <h1>About The Product</h1>
                                <div className='d-flex   align-items-start mt-5'>
                                    <div className="col-md-8">
                                    <p class="text-center">
        <span class="text-success-subtle fs-6 fw-light font-family-Montserrat">Stock Wise Analysis is a comprehensive platform that caters to all your stock market needs. Whether you're a seasoned investor or just starting, our tools are designed to&nbsp;</span>
        <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat"> simplify the complexities of stock analysis,&nbsp;</span>
        <span class="text-success-subtle fs-6 fw-light font-family-Montserrat">empowering you to%nbsp;</span>
        <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">make informed decisions with confidence.&nbsp;</span>
        <span class="text-success-subtle fs-6 fw-light font-family-Montserrat">with&nbsp;</span>
        <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">cutting-edge analytics&nbsp;</span>
        <span class="text-success-subtle fs-6 fw-light font-family-Montserrat">and&nbsp;</span>
        <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">instant alerts,&nbsp;</span>
        <span class="text-success-subtle fs-6 fw-light font-family-Montserrat">StockWiseAnalysis keeps you ahead of the curve, allowing you to navigate the markets with ease.</span>
        </p>
                                           </div>
                                </div>
                            </div>
                            <div className='col-5 d-md-block d-none'>
                                <img style={{ width: '100%', height: '100%' }} src="images/about-us/product-img.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionTwo;