import axios from 'axios';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";

const fetchClosePrice90days = async (symbol) => {
  const url = `${Base_URL_TV}/tvapimmvt?symbols=[${symbol}]`;
  try {
    const response = await axios.get(url);
    const data = response.data;
    console.log("90 days data Received:", data); // Log the entire data to verify its structure

    // Check if data is an array and has at least one element
    if (Array.isArray(data) && data.length > 0) {
      const firstElement = data[0];
      console.log("First element of data:", firstElement); // Log the first element of the data array

      // Check if firstElement is an array and has at least one element
      if (Array.isArray(firstElement) && firstElement.length > 0) {
        const nestedElement = firstElement[0];
        console.log("Nested element:", nestedElement); // Log the nested element

        // Check if nestedElement has the expected properties
        if (nestedElement && nestedElement.close_prices) {
          const closePrices = nestedElement.close_prices;
          console.log("close_prices object:", closePrices); // Log the close_prices object

          // Check if cp90days exists in closePrices
          if (typeof closePrices.cp90days !== 'undefined') {
            console.log("90 days close price data Received:", closePrices.cp90days);
            return closePrices.cp90days;
          } else {
            throw new Error('cp90days not found in close_prices for the given symbol');
          }
        } else {
          throw new Error('close_prices not found in the nested element of data array');
        }
      } else {
        throw new Error('No valid nested data array found for the given symbol');
      }
    } else {
      throw new Error('No valid data array found for the given symbol');
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export default fetchClosePrice90days;
