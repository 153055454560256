import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function SectionOne() {
    return (
        <>
        <div style={{ height: '500px', position: 'relative', width: '100%', marginTop: "90px" ,backgroundColor:"black" }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700', 
                    position: 'absolute', 
                    top: 10, 
                    left: '1%', 
                    zIndex: 'tooltip', //tooltip:1500 
                }} >
                        <Link underline="hover" color="white" href="/">
            Home</Link>
            <Typography color="white" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700'}} >About Us</Typography>
             </Breadcrumbs>
        <video className='videoTag' autoPlay loop muted webkit-playsinline style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                <source src='/videos/aboutUs.mp4' type='video/mp4' />
            </video>
     
        <div className="text-center">
            <div className='col-12' class="aboutusheading">
                <h1>About Us</h1>
            </div>
        </div>
 
  
  <div class="container-fluid trendunnersmall">
    <p class="text-center">
        <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">StockWiseAnalysis </span>
        <span class="text-success-subtle fs-6 fw-light font-family-Montserrat">is a leading platform dedicated to </span>
        <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">providing investors <br />with powerful tools</span>
        <span class="text-success-subtle fs-6 fw-light font-family-Montserrat"> and </span>
        <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat"> comprehensive insights</span>
        <span class="text-success-subtle fs-6 fw-light font-family-Montserrat"> for navigating the stock market. </span>
        </p>
</div>
</div>
        </>
    );
}

export default SectionOne;