import * as React from 'react';
import { useNavigate } from "react-router-dom";

function SectionThree() {
    const navigate = useNavigate();
    function navigateTo(path) {
        navigate(path);
      }
    function handleExplore(evt) {
        navigateTo("/explore");
      }
      function handleExplorefirstedge(evt) {
        navigateTo("/firstedge");
      }
    return (
        <>

<div class="container">
<div class="row">
    <div class="col">
    <div className="text-center justify-content-center align-items-center text-white">
                            <h1>Features</h1>
                        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-1"></div> 
    <div class="col-md-5 text-center justify-content-center align-items-center ">
    <a href="#" target="_self" onClick={handleExplore} style={{ textDecoration: 'none' }}>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="card text-center text-white m-3 col-lg-8 p-5" style={{ backgroundColor: '#ffffff15' }}>
                                <div className="card-image">
                                    <img src="images/about-us/group-11.svg" alt="CSE" className="img-fluid"  width={'45px'}/>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">TrendRunner</h5>
                                    <p className="card-text">Access cutting-edge analytics for valuable insights into market trends, empowering you to make informed portfolio decisions.</p>
                                </div>
                            </div>
                        </div>
                        </a>
    </div>
    <div class="col-md-5 text-center justify-content-center align-items-center ">
    <a href="#" target="_self" onClick={handleExplorefirstedge} style={{ textDecoration: 'none' }}>
                        <div className="d-flex justify-content-center align-items-center" >
                            <div className="card text-center text-white m-3 col-lg-8 p-5" style={{ backgroundColor: '#FFFFFF15' }}>
                                <div className="card-image">
                                    <img src="images/about-us/vector.svg" alt="CSE" className="img-fluid" width={'45px'}/>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">Firstedge Alerts</h5>
                                    <p className="card-text">Stay proactive with instant notifications that keep you informed of market changes, ensuring you never miss a beat.</p>
                                </div>
                            </div>
                        </div>
                        </a>
    </div>
    <div class="col-md-1"></div> 
  </div>
</div>
 </>
    );
}

export default SectionThree;