import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { EmailShareButton, TwitterShareButton } from "react-share";
import ConfirmationDialog_add from "../ConfirmationDialog_add.js";
import ConfirmationDialog_minus from "../ConfirmationDialog_minus.js";
import EditDialog_minus from "./EditDialog_minus.js";
import EditDialog_add from "./EditDialog_add.js";
import FinancialData from "./FinancialData.js";

const CurrentPortfolio = ({ initialUserData }) => {
  const [stocks, setStocks] = useState([]);
  const [currentPrices, setCurrentPrices] = useState({}); // To store current prices
  const [updateData, setUpdateData] = useState(null);
  const [editDialogOpen_add, setEditDialogOpen_add] = useState(false);
  const [editDialogOpen_minus, setEditDialogOpen_minus] = useState(false);

  useEffect(() => {


    fetch('https://api.stockwiseanalytics.com/tv/tvapi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        symbols: '[CALT.N0000]',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });





    const fetchPublicUserData = async () => {
      try {
        const username = localStorage.getItem('unm');
        const res = await axios.get(Base_URL + "/portfolio/stocks", {
          params: { username: username }
        });
        setStocks(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };

    fetchPublicUserData();
  }, []);

  useEffect(() => {
    if (stocks.length > 0) {
      fetchStockData();
    }
  }, [stocks]);
  const localLogoImagePath = "/images/companylogos";

  const removeData = async (id) => {
    try {
      const res = await axios.delete(Base_URL + "/portfolio/delstock", {
        params: { ordid: id }
      });
      toast.success("Stock Data Successfully deleted.");
      window.location.reload();
      setStocks(prevStocks => prevStocks.filter(stock => stock.ordid !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmation = (id) => {
    removeData(id);
  };

  const handleStockModal_add = (stock) => {
    setUpdateData(stock);
    setEditDialogOpen_add(true);
  };

  const handleStockModal_minus = (stock) => {
    setUpdateData(stock);
    setEditDialogOpen_minus(true);
  };

  const closeEditDialog_add = () => {
    setEditDialogOpen_add(false);
    setUpdateData(null);
  };

  const closeEditDialog_minus = () => {
    setEditDialogOpen_minus(false);
    setUpdateData(null);
  };

  const fetchStockData = () => {
   // const symbols = stocks.map(stock => stock.ctick);
   const symbols0 = localStorage.getItem('symbolstring');
   let symbolsArray = [];
   
   if (symbols0) {
      symbolsArray = symbols0.split(','); // Convert string to array
   }
   
   const url = Base_URL_TV + `/tvapi?symbols=[${symbolsArray.join(',')}]`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log("Received data:", data);
        // Update state with fetched data
        const prices = data.reduce((acc, stock) => {
          if (!stock.error) {
            acc[stock.symbol] = stock.close_price;
          }
          return acc;
        }, {});
        setCurrentPrices(prices);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <p className="text-white fs-6 fw-bold font-family-Montserrat">
        CURRENT PORTFOLIO
      </p>
      <div className="row h-25 w-100">
            <div className="text-white text-opacity-20 fs-6 fw-normal font-family-Montserrat col-10 m-0 px-3 py-2 " style={{ backgroundColor: 'black', width: '100%', height: '300px', overflow: 'auto' }}>
                     <table className=" bg-black text-white pl-5 table" class="table-dark mytable" style={{ backgroundColor: 'black' ,width:"100%",padding: "auto" ,margin: "auto"}}>
        
            <thead>
              <tr className="border-bottom border-primary-subtle border-opacity-25">
                <th  align="center"></th>
                <th class="text-start">Stock</th>
                <th class="text-center">Quantity</th>
                <th class="text-center">Avg. Price Purchased</th>
                <th class="text-center">Current Price</th>
                <th class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {stocks.map(stock => (
                <tr key={stock.ordid} className="border-bottom  border-secondary border-opacity-25">
                  <td className="w-1 p-0">
                    <img
                      src={stock.ctick ? `${localLogoImagePath}/${stock.ctick}.png` : 'images/companylogos/default-logo.png'}
                      width="30px" height="auto"
                      alt={stock.cname}
                    />
                  </td>
                  <td className="text-nowrap">{stock.cname}<br />{stock.ctick}</td>
                  <td align="center">{stock.csemco_quantity}</td>
                  <td align="center">{stock.avgprice}</td>
                  <td align="center">{currentPrices[stock.ctick] !== undefined ? currentPrices[stock.ctick].toFixed(2) : 'N/A'}</td>
                  <td className="text-nowrap text-center">
                    <ConfirmationDialog_add
                      title="Confirmation"
                      description="Are you sure you want to proceed?"
                      response={() => handleConfirmation(stock.ordid)}>
                      {(showDialog) => (
                        <i className="bi bi-trash" onClick={showDialog} style={{ color: 'rgb(255, 0, 0)', fontSize: '1rem' }}></i>
                      )}
                    </ConfirmationDialog_add>
                    &nbsp;
                    <TwitterShareButton
                      title={`Hey, I just purchased ${stock.csemco_quantity} stocks from ${stock.cname}. My current avg. price is ${stock.avgprice}`}
                      url={"http://localhost:3001/portfolioanalytics"}
                      hashtags={["hashtag1", "hashtag2"]}
                    >
                      <i className="bi bi-twitter-x" style={{ color: '#fff', fontSize: '1rem' }}></i>
                    </TwitterShareButton>
                    &nbsp;
                    <a href="#" onClick={() => handleStockModal_add(stock)}>
                      <i className="bi bi-file-earmark-plus" style={{ color: 'gold', fontSize: '1rem' }}></i>
                    </a>
                    &nbsp;
                    <a href="#" onClick={() => handleStockModal_minus(stock)}>
                      <i className="bi bi-file-earmark-minus" style={{ color: 'cyan', fontSize: '1rem' }}></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <EditDialog_add
        open={editDialogOpen_add}
        onClose={closeEditDialog_add}
        stockData={updateData}
        onUpdate={(updatedStock) => {
          setStocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
        }}
      />
      <EditDialog_minus
        open={editDialogOpen_minus}
        onClose={closeEditDialog_minus}
        stockData={updateData}
        onUpdate={(updatedStock) => {
          setStocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
        }}
      />
    </>
  );
};

export default CurrentPortfolio;

