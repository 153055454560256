// ImageDisplayprofile.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_URL } from '../Components/BaseURL.js';
const URL = Base_URL; // Your backend server URL

const ImageDisplayprofile = ({ imageId }) => {
  const [imageSrc, setImageSrc] = useState('');
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(`${URL}/upimage/image/${imageId}`, {
          responseType: 'arraybuffer',
        });
        const base64Image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );
        const imageSrc = `data:${response.headers['content-type'].toLowerCase()};base64,${base64Image}`;
        // setImageSrc(imageSrc);
             
        if ((imageSrc === 'data:null;base64,') || (imageSrc === 'data:image/png;base64,')) {  //data:image/png;base64,
          console.log('Fetched image is empty, using default image.');
          setImageSrc('/images/companylogos/default-logo.png');
        } else {
          setImageSrc(imageSrc);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
        setImageSrc('/images/companylogos/default-logo.png'); // Set default image in case of error
      }
    };

    if (imageId) {
      fetchImage();
    }
  }, [imageId]);

  return (
    <div>
      {imageSrc ? (
        <img className="rounded-1 span4 center-block" style={{width:'120px',height:'auto'}} src={imageSrc}/>
         ) : (
        <p>Loading image...</p>
      )}
    </div>
  );
};

export default ImageDisplayprofile;
