import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import { TwitterShareButton } from "react-share";
import axios from "axios";
import { Base_URL } from "../../Components/BaseURL.js";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Close } from '@material-ui/icons';
import AfterconfirmDialog_minus from "./AfterConfirmDialog_minus.js";
const EditConfirmDialog_minus = ({ open, onClose, stockData, onUpdate }) => {
 
  const [isSocialMediaModalOpen, setIsSocialMediaModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [confirmdialogafter, setConfirmdialogafter] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null);
  const [formData, setFormData] = useState({
    InputOrdID: "",
    InputStock: "",
    Inputmax:"",
    InputStockName: "",
    InputSQty:"",
    InputNewQty: "",
    InputAvgPrice: "",  
  });
 
  const handleSocialMediaMessage = (message) => {
    // Handle sending the social media message using the provided message
    console.log("Sending social media message:", message); // Replace with actual logic
    setIsSocialMediaModalOpen(false); // Close the modal after sending
  };
  useEffect(() => {
    if (stockData) {
      const initialData = {
        InputOrdID: stockData.InputOrdID,
        InputStock: stockData.InputStock,
        Inputmax:stockData.Inputmax,
        InputStockName: stockData.InputStockName,
        InputSQty:stockData.InputSQty,
        InputNewQty: stockData.InputNewQty,
        InputAvgPrice: stockData.InputAvgPrice,
      };
      setFormData(initialData);
      setInitialFormData(initialData);
    }
  }, [stockData]);

  const handleconfirmModal = async (formDataStocks) => {
    try {
      const res = await axios.post(
        Base_URL + `/portfolio/stockupdate`,
        formData
      );
       toast.success("Stock Data Successfully redused.");
       console.log('---formData----'+formData);
       setUpdateData(formData);
       setConfirmdialogafter(true);
    } catch (error) {
      toast.error("Error submitting data. Please try again later.");
      console.error(error);
    }
};

   const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        Base_URL + `/portfolio/stockupdate`,
        formData
      );
      // toast.success("Stock Data Successfully updated.");
      setIsSocialMediaModalOpen(true);
      onUpdate(formData); // Pass updated data to parent
      onClose(); // Close the modal
    } catch (error) {
      toast.error("Error submitting data. Please try again later.");
      console.error(error);
    }
  };
  const handleCancelSocialMedia = () => {
    setIsSocialMediaModalOpen(false); // Close the modal
  };
  const closeDialog_afterconfirm = () => {
    setConfirmdialogafter(false);
    setUpdateData(null);
     window.location.reload();
  };
  return (
<>
 <div>
      <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
    >
                        <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">Confirm reduced quantity</Typography>
        <div className="col-auto font-family-Montserrat text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}>
        <Close/>
        </IconButton></div>
      </DialogTitle>
       <DialogContent dividers>
        <ToastContainer />
        <form class="form-inline">
          <input type="hidden" name="InputOrdID" id="InputOrdID"  value={formData.InputOrdID}></input>
          <input type="hidden" name="InputStock" id="InputStock" value={formData.InputStock}></input>
          <input type="hidden" name="Inputmax" id="Inputmax" value={formData.Inputmax}/>
          <div className="row g-3 align-items-center">
          <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-Montserrat">Stock</p>
            </div>
            <div className="col-7">
            <input type=""  
                   value={formData.InputStockName}
                        className="text-black bg-white fw-light w-100 float-start text-start"
               id="InputStockName"
               name="InputStockName"
                    class="form-control text-start" 
                    disabled/> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-Montserrat">Current Quantity</p>
            </div>
            <div className="col-6">
            <input
                type="number"
                className="form-control text-black bg-white fw-light w-20"
                id="InputQty"
                name="InputQty" disabled
                class="form-control text-end" 
                value={formData.Inputmax}
                    />
         
            </div>
            </div>           
            <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-Montserrat">Sold Quantity</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="form-control text-black bg-white fw-light w-50"
                id="InputSQty"
                name="InputSQty"
                disabled
                class="form-control text-end" 
                value={formData.InputSQty}
                         />
            </div>
            </div>
            <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-Montserrat">New Position Quantity</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="form-control text-black bg-white fw-light w-50"
                id="InputNewQty"
                name="InputNewPAvgPrice"
                class="form-control text-end" 
                disabled
                value={formData.Inputmax-formData.InputSQty}
                       />
            </div>
            </div>
            <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-Montserrat">Current Avg. Price Purchased</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="form-control text-black bg-white fw-light w-20"
                id="InputAvgPrice"
                name="InputAvgPrice" disabled
                class="form-control text-end" 
                value={formData.InputAvgPrice}
                          />
            </div>
            </div>
   
          </div>
          <div className="p-4"></div>
          <div className="col-auto text-end"> 
          <button type="button" className="btn btn-dark border border-white mr-1" onClick={onClose}>
          Close</button>  
     
            <button type="button" onClick={() => handleconfirmModal(formData)} className="btn btn-dark border border-white mr-1">Save Changes</button>
      
           </div>
        </form>
      </DialogContent>
    </Dialog>
    </div>
    <AfterconfirmDialog_minus
        open={confirmdialogafter}
        onClose={closeDialog_afterconfirm}
         stockData={updateData}
      />
    </>
  );
};

export default EditConfirmDialog_minus;
