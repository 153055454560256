import React from 'react';
import './landing.css';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
function Exploretrendrunner() {
  const navigate = useNavigate();
  function navigateTo(path) {
      navigate(path);
    }
  function handleExplore(evt) {
      navigateTo("/portfolio");
    }
    return (
        <>
        <div style={{ height: '500px', position: 'relative', width: '100%', marginTop: "90px" ,backgroundColor:"black" }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700', 
                    position: 'absolute', 
                    top: 10, 
                    left: '1%', 
                    zIndex: 'tooltip', //tooltip:1500 
                }} >
                        <Link underline="hover" color="white" href="/">
            Home</Link>
            <Typography color="white" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700'}} >Services</Typography>
        <Typography color="white" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700'}} >TrendRunner (Portfolio Analytics Services)</Typography>
      </Breadcrumbs>
        <video className='videoTag' autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                <source src='/videos/landing.mp4' type='video/mp4' />
            </video>
        <div className="text-center">
            <div className='col-12' class="trendunner">
                <span class="trendunner1">Explore our</span> <span class="trendunner2">TrendRunner</span>
            </div>
        </div>
        </div>

  <div class="container-fluid py-3 trendunnersmall">
    <p class="text-center  m-0 px-3 py-2">
        <span class="trendunnersmall1">
        TrendRunner is designed to provide you with the&nbsp;
        </span>
        <span class="trendunnersmall2">
        latest market trends&nbsp;
        </span>
        <span class="trendunnersmall1">
        and insights to help you make&nbsp;
        </span>
        <span class="trendunnersmall2">
        informed portfolio decisions.<br />
        </span>
        <span class="trendunnersmall1">
        Our advanced analytics tools ensure you stay ahead of the market.
        </span>
    </p>
</div>

<div className="bg-black ">
                <div className='container' >
                    <div className="row text-black text-center">

                    <div className="col-lg-4 d-flex justify-content-center align-items-center">
                        <a href="#" target="_self" onClick={handleExplore} style={{ textDecoration: 'none' }}> 
                            <div className="card text-center text-black rounded-4 border border-1   bg-white" style={{ backgroundColor: '#FFFFFF15' }} >
                                <div className="card-image">
                                    <img src="/images/trendrunner/portfolio.png" alt="CSE" className="img-fluid rounded-4" />
                                </div>
                                <div className="card-body">
                                <div id="component" class="container-fluid py-3">
    <p class="text-black fs-4 fw-semibold font-family-Montserrat m-0 px-3 py-3">
    Create your own Portfolio
    </p>
</div>
    <div id="component" class="container-fluid py-3">
    <p class="text-start  m-0 px-3 py-2">
        <span class="text-black fs-6 fw-normal font-family-Montserrat">
        Create your own portfolio and&nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-Montserrat">
        view the portfolio performance&nbsp;
        </span>
        <span class="text-black fs-6 fw-normal font-family-Montserrat">
        in terms of Capital deployed, Gain and Annual Projected Dividend within the&nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-Montserrat">
        selected time.
        </span>
 
    </p>
</div>

<div id="component" class="container-fluid py-3">
    <div class="position-relative  row">
        <div class="left-0 top-0 position-absolute bg-purple rounded-1 col-12">
        </div>
        <p class="position-absolute text-center text-white fs-6 fw-bold font-family-Montserrat col-8 m-0 px-3 py-2  rounded-1" style={{backgroundColor:'rgba(102, 42, 159, 1)'}}>
        Explore
        </p>
    </div>
    <div className='p-3'></div>
</div>
                                </div>
                            </div>
                            </a>
                        </div>




                        <div className="col-lg-4 d-flex justify-content-center align-items-center">
                        <a href="#" target="_self" onClick={handleExplore} style={{ textDecoration: 'none' }}> 
                            <div className="card text-center text-black rounded-4 border border-1   bg-white" style={{ backgroundColor: '#FFFFFF15' }} >
                                <div className="card-image">
                                    <img src="/images/trendrunner/volinfo.png" alt="CSE" className="img-fluid rounded-4" />
                                </div>
                                <div className="card-body">
                                <div id="component" class="container-fluid py-2">
    <p class="text-black fs-4 fw-semibold font-family-Montserrat m-0 px-3 py-1">
    Today’s Price, Volume Information and News
    </p>
</div>
    <div id="component" class="container-fluid py-3">
    <p class="text-start  m-0 px-3 py-2">
        <span class="text-black fs-6 fw-normal font-family-Montserrat">
        View the&nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-Montserrat">
        price and volume information&nbsp;
        </span>
        <span class="text-black fs-6 fw-normal font-family-Montserrat">
        of each company within the&nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-Montserrat">
        selected time range&nbsp;
        </span>
        <span class="text-black fs-6 fw-normal font-family-Montserrat">
        as well as&nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-Montserrat">
        read financial news and researches.
        </span>
    </p>
</div>

<div id="component" class="container-fluid py-3">
    <div class="position-relative  row">
        <div class="left-0 top-0 position-absolute bg-purple rounded-1 col-12">
        </div>
        <p class="position-absolute text-center text-white fs-6 fw-bold font-family-Montserrat col-8 m-0 px-3 py-2  rounded-1" style={{backgroundColor:'rgba(102, 42, 159, 1)'}}>
        Explore
        </p>
    </div>
    <div className='p-3'></div>
</div>
                                </div>
                            </div>
                            </a>
                        </div>


                        <div className="col-lg-4 d-flex justify-content-center align-items-center">
                        <a href="#" target="_self" onClick={handleExplore} style={{ textDecoration: 'none' }}> 
                            <div className="card text-center text-black rounded-4 border border-1   bg-white" style={{ backgroundColor: '#FFFFFF15' }} >
                                <div className="card-image">
                                    <img src="/images/trendrunner/market.png" alt="CSE" className="img-fluid rounded-4" />
                                </div>
                                <div className="card-body">
                                <div id="component" class="container-fluid py-3">
    <p class="text-black fs-4 fw-semibold font-family-Montserrat m-0 px-3 py-4">
    Market Sentiment

    </p>
</div>
    <div id="component" class="container-fluid py-3">
    <p class="text-start  m-0 px-3 py-2">
        <span class="text-black fs-6 fw-normal font-family-Montserrat">
        Find out who has&nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-Montserrat">
        purchased shares&nbsp;
        </span>
        <span class="text-black fs-6 fw-normal font-family-Montserrat">
        from the&nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-Montserrat">
        same company as you.
        </span>
     </p>
     <div className='p-3'></div>
</div>

<div id="component" class="container-fluid py-3">
    <div class="position-relative  row">
        <div class="left-0 top-0 position-absolute bg-purple rounded-1 col-12">
        </div>
        <p class="position-absolute text-center text-white fs-6 fw-bold font-family-Montserrat col-8 m-0 px-3 py-2  rounded-1" style={{backgroundColor:'rgba(102, 42, 159, 1)'}}>
        Explore
        </p>
    </div>
    <div className='p-3'></div>
</div>
                                </div>
                            </div>
                            </a>
                        </div>



                        
                    </div>
                </div>
            </div>

</>
    );
}

export default Exploretrendrunner;