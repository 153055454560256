// export default AfterConfirmDialog;
import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { EmailShareButton, TwitterShareButton } from "react-share";
const AfterconfirmDialog = ({ open, onClose , stockData }) => {
    const [formData, setFormData] = useState({
        InputOrdID: "",
        InputStock: "",
        InputQty: "",
        InputAvgPrice: "",
        InputCurrentPrice: "100",
        InputStockName: "",
        username: localStorage.getItem("unm"),
        InputNewQty: "",
        InputNewPQty: "",
        InputNewAvgPrice: "",
        InputNewPAvgPrice: "",
        Inputmax:"",
      });

      useEffect(() => {
        if (stockData) {
          setFormData({
            InputOrdID: stockData.InputOrdID,
            InputStock: stockData.InputStock,
            InputQty: stockData.InputQty,
            InputAvgPrice: stockData.InputAvgPrice,
            InputCurrentPrice: stockData.InputCurrentPrice,
            InputStockName: stockData.InputStockName,
            username: localStorage.getItem("unm"),
            InputNewQty: stockData.InputNewQty,
            InputNewPQty: stockData.InputNewPQty,
            InputNewAvgPrice:stockData.InputNewAvgPrice,
            InputNewPAvgPrice: stockData.InputNewPAvgPrice,
            Inputmax:stockData.csemco_quantity,
          });
        }
      }, [stockData]);
 return (
   
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Share Update ?</DialogTitle>
      <DialogContent>
      <div class="icon-demo mb-4 border rounded-3 d-flex align-items-center justify-content-center p-3 py-6" style={{fontsize: '10em', role:'img', arialabel:'Twitter X - large preview'}}>
                     <TwitterShareButton
                       title={`Today I purchased ${formData.InputNewQty} Stocks from ${formData.InputStockName} at LKR ${formData.InputNewAvgPrice}!
                                Feeling Bullish!`}
                       url={"http://localhost:3001/portfolioanalytics"}
                       hashtags={["hashtag1", "hashtag2"]}
                     >
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
   <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
 </svg>
 
                     </TwitterShareButton>
 
         </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AfterconfirmDialog;