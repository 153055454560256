// import React, { useState, useEffect } from 'react';
// import '../Portfolio.css';
// import PortfolioHeading from '../PortfolioHeading;';
// import CurrentPortfolio from '../CurrentPortfolio';
// import PortfolioCreation from '../PortfolioCreation';
// import DonutChart from '../Charts/DonutCharts/DonutChart01';
// import DChart from '../DChart';
// import PortfolioPerformance from '../../portfolio/apd/PortfolioPerformance';

// import LineChart from '../linechart';

// import { Link, useNavigate ,useLocation} from 'react-router-dom';


// function Portfolio() {
//   const location = useLocation();
//   const [currentPageName, setCurrentPageName] = useState('');
//   const navigate = useNavigate();
//   useEffect(() => {
//     const updatePageName = () => {
//       const pathname = window.location.pathname; // Use window.location
//       console.log('---pathname---'+pathname);
//       switch (pathname) {
//         case '/portfolio':
//           setCurrentPageName('PortfolioPerformance');
//           break;
//         case '/portfolio/portfolio-performance':
//           setCurrentPageName('PortfolioPerformance (Today)'); // Update for clarity
//           break;
//         case '/portfolio/portfolio-performance/last-month':
//           setCurrentPageName('PortfolioPerformance_lm');
//           break;
//         case '/portfolio/portfolio-performance/last-three-months':
//           setCurrentPageName('PortfolioPerformance_lm');
//           break;
//         case '/portfolio_gain':
//           setCurrentPageName('PortfolioPerformance');
//           break;
//         case '/portfolio_apd':
//           setCurrentPageName('PortfolioPerformance');
//           break;
//           default:
//             setCurrentPageName(null);
//       }
//     };

//     updatePageName(); // Call initially on component mount
// }, []); // Empty dependency array for one-time execution

//   // Handle navigation using useNavigate hook
//   const handleNavigate = (performancePeriod) => {
//     navigate(`/portfolio/portfolio-performance/${performancePeriod}`);
//   };
// return (
//     <>
//       <div className="layout">
//         <div className="p-5"></div>
//         <header>
//           <PortfolioHeading />
//         </header>
//         <div className="row">
//           <div className="col-md-12">
//            <PortfolioPerformance/>
//           </div>
//         </div>
//         <main className="content">
//           <div className="row">
//             <div className="col-md-7">
//               <CurrentPortfolio />
//             </div>
//             <div className="col-md-5">
//               <PortfolioCreation />
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-md-7">
//           <LineChart/>
//             </div>
//             <div className="col-md-5">
       
//             </div>
//           </div>
          
//           <currentPageName/>
//           {currentPageName === 'PortfolioPerformance'}
//           {currentPageName === 'PortfolioPerformance_gs'}
//           {currentPageName === 'PortfolioPerformance_apd'}
//           {/* Add more conditions based on your requirements */}
//         </main>
//         <footer></footer>
//       </div>
//     </>
//   );
// }
//   export default Portfolio;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import PortfolioHeading from '../PortfolioHeading;';
import CurrentPortfolio from '../CurrentPortfolio';
import PortfolioCreation from '../PortfolioCreation';
import Todayspricevolume from '../Todayspricevolume';
import PortfolioPerformance from '../../portfolio/apd/PortfolioPerformance';
import Linechart from '../linechart';
import Finacialinfo from '../FinancialInfo.js';
import CSENEWS from '../CSENews.js';
import DividentTracker  from '../DividentTracker.js';
import '../Portfolio.css';
import RESEARCHInfo from '../ResearchInfo.js';
function Portfolio() {
  const location = useLocation();
  const [currentPageName, setCurrentPageName] = useState('');

  useEffect(() => {
    const updatePageName = () => {
      const pathname = location.pathname;
      switch (pathname) {
        case '/portfolio_gain':
          setCurrentPageName('PortfolioPerformance');
          break;
        case '/portfolio_gain/today':
          setCurrentPageName('PortfolioPerformanceToday');
          break;
        case '/portfolio_gain/last-month':
          setCurrentPageName('PortfolioPerformanceLMonth');
          break;
        case '/portfolio_gain/last-three-months':
          setCurrentPageName('PortfolioPerformanceLThreeM');
          break;
        default:
          setCurrentPageName(null);
      }
    };

    updatePageName();
  }, [location.pathname]);
  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: 'rgba(256,256,256, 0.3)',
            backgroundColor: 'rgba(256,256,256, 0.3)',
            height: 1
        }}
    />
);
  return (
    <>
      <div className="layout fluid">
      <header class="header-area header-sticky">
      <div class="p-4"></div>
          <PortfolioHeading />
        </header>
        <main className="content">
          <div className="row  h-100 justify-content-center align-items-center">
          {/* <div className="col-md-1"></div> */}
            <div className="col-md-12">
              <PortfolioPerformance />
            </div>
            {/* <div className="col-md-1"></div> */}
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-8">
              <CurrentPortfolio />
            </div>
            <div className="col-md-4">
              <PortfolioCreation />
            </div>
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-6">
              <Todayspricevolume />
            </div>
            <div className="col-md-6">

              <Linechart/>
                    </div>
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-12">
            <Finacialinfo/>
            </div>
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-12">
            <CSENEWS/>
            </div>
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-12">
            <RESEARCHInfo/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <DividentTracker/>
            </div>
          </div>
        </main>
        <footer></footer>
      </div>
    </>
  );
}

export default Portfolio;