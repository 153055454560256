// export default EditDialog;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { Close } from '@material-ui/icons';
import axios from "axios";
import { Base_URL } from '../../Components/BaseURL.js';
import TextField from "@material-ui/core/TextField";
import AlertcreateModal from "./alertcreateModal.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { EmailShareButton, TwitterShareButton } from "react-share";
import ChildModal from './alertcreationModal.js';
const NewAlerModal = ({ open, onClose, data }) => {
  const [editDialogOpen_add, setEditDialogOpen_add] = useState(false);
  const [newAlertModalOpen, setNewAlertModalOpen] = useState(false);
  console.log('newalertmodal---'+data);
  const [stocks, setStocks] = useState([]);
    const min = 1;
 const Inputmax = "";
 const [isClicked, setIsClicked] = useState(false);
 const [alerttypes, setAlertTypes] = useState([]);
 const [editDialogEmailOpen, setEditDialogEmailOpen] = useState(false);
 useEffect(() => {
  const fetchAlertData = async () => {
      try {
          const username = localStorage.getItem('unm');
          const res = await axios.get(Base_URL + "/alert/getalerts", {
              params: { username: username }
          });
          setAlertTypes(res.data);
      } catch (error) {
          console.error(error);
          toast.error("Failed to fetch data");
      }
  };

  fetchAlertData();
}, []);


const handleModal = (data) => {
  // setUpdateData(data);
  setEditDialogEmailOpen(true);
};

  const [updateData, setUpdateData] = useState(null);
  const [value, setValue] = useState();

// Handle changes in form inputs
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(Base_URL + `/portfolio/updatestock`, '');
      toast.success("Stock Data Successfully updated.");
      setIsClicked(false)
      // onUpdate(formDataStocks); // Pass updated data to parent
       onClose(); // Close the modal
       } catch (error) {
      //toast.error("Error submitting data. Please try again later.");
      console.error(error);
    }
  };
  const closeEditDialog_add = () => {
    setEditDialogOpen_add(false);
    setUpdateData(null);
  };
    const handleStockModal = (alertdata) => {
       setIsClicked(true)
    // setUpdateData(formDataStocks);
    setEditDialogOpen_add(true);
  };

  const [openalt, setOpenalt] = useState(false);

  const handleOpenalt = () => {
    setOpenalt(true);
  };

  const handleClosealt = () => {
    setOpenalt(false);
  };

  const handleCloseDialog_add = () => {
    setNewAlertModalOpen(false);
    setUpdateData(null);
  };

  // const handleAlertModal = (value) => {
  //   setUpdateData(value);
  //   setNewAlertModalOpen(true);
  // };
  const handleAlertModal = (value) => {
    const updatedData = { ...data, tick: data["0"], ...value };
    delete updatedData["0"]; // Remove the old "0" key
    setUpdateData(updatedData);
    setNewAlertModalOpen(true);
  };
  


  return (
    <>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
    >
    <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">New Alert</Typography>
        <div className="col-auto font-family-Montserrat text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}s>
        <Close/>
        </IconButton></div>
      </DialogTitle>
      <DialogContent dividers>
        <ToastContainer />
        <form onSubmit={handleSubmit} class="form-inline">

<div id="component" class="container-fluid py-0">
    <p class="text-start text-dark fs-6 fw-normal font-family-Montserrat  m-0 px-0 py-0">
    Select the alert type.
    </p>
</div>
{alerttypes.map((alerttyp, index) => (
                <a href="#" className="text-decoration-none" onClick={() => handleAlertModal({...alerttyp})}><div class="col-md-12"> 
                <div class="card p-3"   style={{background: alerttyp.csem_alerts_bg}}>
                    <div class="d-flex flex-row mb-3">
                      <img src={`${'images/alerts/'}${alerttyp.csem_alerts_icon}`} width="36" height="36"/>&nbsp;&nbsp;
                        <div class="d-flex flex-column ml-2"><span>{alerttyp.csemcat_name}</span>
                        <span class="text-black-50">{alerttyp.csem_alerts_notice}</span><span class="ratings"><i class="fa fa-star"></i>
                        <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i></span></div>
                    </div>
                   </div>
                   <div className="p-2"></div>
            </div></a>
 ))}

           </form>
      </DialogContent>
    </Dialog>
    {newAlertModalOpen && (
        <ChildModal
          open={newAlertModalOpen}
          onClose={handleCloseDialog_add}
          data={updateData}
        />
      )}
           </>
  );
};

export default NewAlerModal;

