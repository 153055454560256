// fetchCurrentPrice.js
import axios from 'axios';
import { Base_URL } from '../../Components/BaseURL.js';
const fetchDivPShare = async () => {
  const symbols = localStorage.getItem('symbolstring');
  const username = localStorage.getItem('unm');
  let inputString = symbols.replace(/[() ]/g, '');
  let arrayOfStrings = inputString.split(',');
  arrayOfStrings = arrayOfStrings.map(str => `'${str}'`);
  let resultString = arrayOfStrings.join(',');
    try {
    const res = await axios.get(Base_URL + "/fininfo/getdivpshare", {
      params: { username: username, symbols: resultString }
  });
        return res.data;
    
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export default fetchDivPShare;


