// export default FinancialInfo;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import "bootstrap-icons/font/bootstrap-icons.css";
import { TwitterShareButton } from "react-share";

const MarketSentiment = ({ initialUserData }) => {
    const [fininfor, setFininfor] = useState([]);
    const [matchingData, setMatchingData] = useState({});

    const username = localStorage.getItem('unm');
    const symbols = localStorage.getItem('symbolstring');
    const localLogoImagePath = "/images/companylogos";
    
    let inputString = symbols.replace(/[() ]/g, '');
    let arrayOfStrings = inputString.split(',');
    arrayOfStrings = arrayOfStrings.map(str => `'${str}'`);
    let resultString = arrayOfStrings.join(',');

    useEffect(() => {
        const fetchFiniData = async () => {
            try {
                const res = await axios.get(Base_URL + "/portfolio/msentiment", {
                    params: { username: username, symbols: resultString }
                });
                setFininfor(res.data);
                // console.log('---data----'+JSON.stringify(res.data));
  
            } catch (error) {
                console.error(error);
                toast.error("Failed to fetch data");
            }
        };

        fetchFiniData();
    }, [username, resultString]);
    const handleShare = () => {
            axios.post("http://127.0.0.1:3000/upload/imagetotweet", {})
              .then(res => {
                const url = res.data.imageUrl;
                const twitterURL = `https://twitter.com/share?url=${url}&text=Check%20out%20this%20image&hashtags=yourHashtags`;
                console.log('--twitterURL--'+twitterURL);
                window.open(twitterURL, "twitter");
              })
              .catch(err => console.log(err, "Error trying to tweet"));
        
      };
    return (
        <>
            <p className="text-white fs-6 fw-bold font-family-Montserrat">
            MARKET SENTIMENT
            </p>
            <div id="component" className="container-fluid py-3" style={{ backgroundColor: 'black', width: '100%', height: '300px', overflow: 'auto' }}>
                <table id="example" className="table table-striped table-dark font-family-Montserrat mytable">
                                  <tbody>
                        {fininfor.length === 0 ? (
                            <tr>
                                <td colSpan="9">No data available</td>
                            </tr>
                        ) : (
                            fininfor.map(fininf => (
                                <tr key={fininf.id} className="border-bottom border-secondary border-opacity-25">
                                    <td>
                                        <img
                                            src={fininf.ctick ? `${localLogoImagePath}/${fininf.ctick}.png` : 'images/companylogos/default-logo.png'}
                                            width="30px" height="auto"
                                            alt={fininf.ctick}
                                        />
                                        {fininf.ctick}
                                    </td>
                                    <td>{fininf.csemc_name}<br />The {fininf.csemc_name} is also in {fininf.portpolios} portfolios</td>
                    <td>     <TwitterShareButton
              url={"http://127.0.0.1:3000"}
              title="Hey, I just shared my stocks"
              hashtags={["hashtag1", "hashtag2"]}
              onClick={handleShare}
              // onClick={shareOnTwitter}
              className=" Demo__some-network__share-button font-family-Montserrat"
            >
              <i className="btn btn-secondary bi bi-twitter-x font-weight-normal" style={{ color: '#fff', fontSize: '1rem' }}>&nbsp;&nbsp;&nbsp;Share</i>
            </TwitterShareButton></td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default MarketSentiment;
