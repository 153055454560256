// fetchCurrentPrice.js
import axios from 'axios';
// import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
const fetchCurrentPrice = async (symbol) => {
  const url = `${Base_URL_TV}/tvapi?symbols=[${symbol}]`;
  try {
    const response = await axios.get(url);
    const data = response.data;
   // console.log("Current Price data Received:", data);
    if (data.length > 0 && !data[0].error) {
      console.log("Current Close Price data Received:", data[0].close_price);
      return data[0].close_price;
    } else {
      throw new Error('No data found for the given symbol');
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export default fetchCurrentPrice;


