import { Link, useNavigate ,useLocation} from 'react-router-dom';
import Aertmanagementheader from './alertmanagementheader';
import Alertmanagementcreate from './alertmanagementcreate';
import Alertmanagementcreated from './alertmanagementcreated';

function alertmanagement() {
    return (
   <>
    <div class="layout">
  <header>
  <Aertmanagementheader/>
   </header>
  <main class="content">
  <div class="modal-body row">
  <div class="col-md-12">
  <Alertmanagementcreate/>
  </div>
</div>
  
  </main>
  <footer>
  {/* <SectionFour /> */}
   </footer>
</div> 


{/* <Alertmanagementcreated/> */}
</>
    );
}

export default alertmanagement;