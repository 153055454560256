import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../../Components/BaseURL.js';
import {
  PieChart, Pie, Label, Cell, Tooltip, ResponsiveContainer
} from 'recharts';
import '../Portfolio.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import FetchDivPShare from "../fetchDivPShare";
import fetchCPrice from '../fetchCurrentPrice';
import html2canvas from 'html2canvas';
import "bootstrap-icons/font/bootstrap-icons.css";
import { chartColors } from "../colors.js";
import domtoimage from "dom-to-image";
import { TwitterShareButton } from "react-share";
import moment from 'moment';
import { calcDate } from '../../calcDate'; 
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Pmenu from './PortfolioPerformance_menu.js';
const PortfolioPerformance = ({ initialUserData }) => {
  const [stocks, setStocks] = useState([]);
  const [menudata, setMenudata] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalpfsize, setTotalpfsize] = useState(0);
  const [totalqty, setTotalqty] = useState(0);
  const [Tots, setTots] = useState([]);
  const navigate = useNavigate();
  const [pfcompStrings, setPfcompStrings] = useState([]);
  const [gainStrings, setGainStrings] = useState([]);
  const chartRef = useRef();
  const backgColor = chartColors;
  const imageRef = useRef(null);
  const [totalgain, setTotalGain] = useState(0);
  const [divpershare, setDivPershare] = useState([]);
  const [dividendData, setDividendData] = useState({});

  useEffect(() => {
    const fetchPublicUserData = async () => {
      try {
        const username = localStorage.getItem('unm');
        const res = await axios.get(Base_URL + "/portfolio/stocks", {
          params: { username: username }
        });
        setStocks(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };

    fetchPublicUserData();
  }, []);

  const fetchDividendData = useCallback(async () => {
    try {
      const DivPShareData = await FetchDivPShare();
      const dividendDataMap = DivPShareData.reduce((acc, data) => {
        acc[data.stock_name] = parseFloat(data.dividend_per_share);
        return acc;
      }, {});
      setDividendData(dividendDataMap);
    } catch (error) {
      console.error('Error fetching dividend data:', error);
    }
  }, []);

  useEffect(() => {
    fetchDividendData();
  }, [fetchDividendData]);

  const calculateTotal = useCallback(async () => {
    try {
      let totalValue = 0;
      let tqty = 0;
      let totalPFSizeValue = 0;
      let labelsSet = new Set();
      let calculatedTots = [];
      let newGainStrings = [];
      let newPfcompStrings = [];
      let dnutpcnt = 0;

      for (let i = 0; i < stocks.length; i++) {
        const stock = stocks[i];
        const label = stock.cname;
      
        if (!labelsSet.has(label)) {
          labelsSet.add(label);
          const quantity = stock.csemco_quantity;
          const tick = stock.ctick;
          const dividendPerShare = dividendData[tick] || 0;

          const gain = dividendPerShare * quantity;
          const gainString = gain.toFixed(2);
          totalValue += Math.abs(gain);
          calculatedTots.push(gain.toFixed(2));
          newGainStrings.push(Math.round(gainString));
          
          const pfcomp = parseFloat(stock.avgprice) * quantity;
          const pfcompString = Math.round(pfcomp);
          tqty += quantity;
          totalPFSizeValue += pfcomp;
          newPfcompStrings.push(pfcompString);
        }
      }
      console.log('---totalPFSizeValue--'+totalPFSizeValue);

      setTotalpfsize(totalPFSizeValue);
      setTotalGain(totalPFSizeValue);
      setTotal(totalValue);   
      setPfcompStrings(newPfcompStrings);
      setTots(newGainStrings);
      setTotalqty(dnutpcnt);
    } catch (error) {
      console.error('Error calculating total:', error);
    }
  }, [stocks, dividendData]);

  useEffect(() => {
    const fetchData = async () => {
      await calculateTotal();
    };
    fetchData();
  }, [stocks, calculateTotal]);

  const downloadChartAsImage = async () => {
    const datapdf = document.getElementById('Doughnutrefid');
    try {
      html2canvas(datapdf, { backgroundColor: 'black', allowTaint: true, useCORS: true }).then((canvas) => {
        var link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'myChart.png';
        link.click();
      });
    } catch (error) {
      console.error('Error downloading the chart:', error);
      toast.error('Failed to download chart');
    }
  };

  function handleSelect(evt) {
    setMenudata(evt);
  }

  function navigateTo(path) {
    navigate(path);
  }

  function handleSelect_gainsnapshot(evt) {
    navigateTo("/portfolio");
  }

  function handleSelect_gain(evt) {
    navigateTo("/portfolio_gain");
  }

  function handleSelect_apd(evt) {
    navigateTo("/portfolio_apd");
  }

  function handleSelect_gs_t(evt) {
    navigateTo("/portfolio_gain/today");
  }

  function handleSelect_gs_lm(evt) {
    navigateTo("/portfolio_gain/last-month");
  }

  function handleSelect_gs_ltm(evt) {
    navigateTo("/portfolio_gain/last-three-months");
  }

  const handleShare = () => {
    let node = document.getElementById('Doughnutrefid');
    domtoimage.toPng(node)
      .then(dataUrl => {
        axios.post("http://127.0.0.1:3000/upload/imagetotweet", { dataUrl: dataUrl })
          .then(res => {
            const url = "www.swa.lk";
            const via = "SWA";
            const title = res.data.message;
            const hashtags = "reactJS,tweet";
            const twitterURL = `https://twitter.com/share?url=${url}&text=${title}&via=${via}&hashtags=${hashtags}`;
            window.open(twitterURL, "twitter");
          })
          .catch(err => console.log(err, "Error trying to tweet"));
      })
      .catch(err => console.log(err));
  };

  const chartData = stocks.map((stock, index) => ({
    name: stock.cname,
    value: stock.csemco_quantity * (dividendData[stock.ctick] || 0),
    tick: stock.ctick,
    color: backgColor[index]
  }));

  return (
    <>
      <div className="container">
      <div className="row">
          <div className="col-md-12">
            <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="white" href="/">
            Home</Link>
        <Link underline="hover" color="white" href="/portfolio">
        Portfolio Analytics</Link>
        <Typography color="white">Annual Projected Dividend</Typography>
      </Breadcrumbs>
          </div>
        </div>
      <Pmenu/>

        <div className="row" id="Doughnutrefid">
          <div className="col-md-8 p-0">
            <div ref={chartRef} style={{ backgroundColor: 'rgba(255, 255, 255, 0.07)', height: "60vh", position: "relative", marginBottom: "0%", padding: "0%" }} className="container d-flex align-items-center justify-content-center p-0 text-white font-family-Montserrat fontSize='12px'">
              <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                  <Pie
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    fontSize={"14px"}
                    innerRadius={120}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, value }) => `${name}: ${value} LKR`}
                  >
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={backgColor[index % backgColor.length]} />
                    ))}
                  </Pie>
                 
                  <Tooltip wrapperStyle={{ fontFamily: 'Montserrat', fontSize: '12px' }} cornerRadius={15} />
                  <text
                    x={450}
                    y={160}
                    fontSize="25px"
                    textAnchor="middle"
                    style={{ fontWeight: "bold" }}
                    fill="white"
                    dominantBaseline="middle"
                  >
                    {
                      totalpfsize !== 0 ?
                        (((parseInt(total) * 100) / parseInt(totalpfsize)).toFixed(2)) + "%"
                        :
                        "0%"
                    }
                  </text>
                
                  <text x={440} y={190} textAnchor="middle" fill="white" dominantBaseline="middle">Projected Dividend</text>
                  <text x={450} y={220} textAnchor="middle" fill="white" dominantBaseline="middle">{total.toFixed(2)} LKR</text>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center justify-content-center font-family-Montserrat" style={{ backgroundColor: 'rgba(255, 255, 255, 0.07)' }}>
            <table className="font-family-Montserrat text-white" style={{ fontSize: '14px' }}>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">Stock</th>
                  <th>Projected Dividend (LKR)</th>
                </tr>
              </thead>
              <tbody>
                {stocks.map((stock, index) => (
                  <tr key={index}>
                    <td><button style={{ backgroundColor: backgColor[index], color: backgColor[index], borderRadius: 150, overflow: 'hidden' }}>&nbsp;</button></td>
                    <td style={{ color: backgColor[index] }}>{stock.cname}</td>
                    <td className="text-end">{Tots[index]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioPerformance;
