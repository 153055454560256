
import React, { useEffect, useState } from 'react';
import './ProfileSettings.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import ImageDisplay from '../ImageDisplayprofile';
import { calcDate } from '../calcDate'; // Adjust the path if needed
import moment from 'moment';
function ProfileSettingsHeading() {
  const username = localStorage.getItem('unm');
  const userid = localStorage.getItem('userid');
  const crdate = localStorage.getItem('crdate');
  const today = new Date();
  const [joinstmt, setJoinstmt] = useState('');

  useEffect(() => {
    // Format dates for calcDate function
    const formattedToday = moment(today).format('YYYY-MM-DD');
    const formattedCrDate = moment(crdate).format('YYYY-MM-DD');

    // Calculate date difference using calcDate
    const dateDifference = calcDate(formattedToday, formattedCrDate);
    if (JSON.stringify(dateDifference.result) === '""') {
      setJoinstmt(' today');
    } else {
      setJoinstmt(JSON.stringify(dateDifference.result));
    }
  }, [today, crdate]);

  return (
    <>
      <div className="container">
        <div className="row align-items-start bg-black text-white centered-text-profilesettings">
          <div className="row">
            <div className="col-md-4">
              <div className="col" id="CSEMaverick">
                <table className="table-borderless bg-black">
                  <tr>
                    <td className="d-flex justify-content-start" colSpan="2" id="CSEMaverick">
                      CSEMaverick
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2"></td>&nbsp;
                  </tr>
                  <tr>
                    <td className="d-flex justify-content-start border-0">
                      <ImageDisplay imageId={userid} />
                    </td>
                    <td className="justify-content-start text-nowrap">
                      <div className="row">
                        <div className="col font-family-Montserrat text-success-subtle fs-6 fw-normal">
                          &nbsp;&nbsp;&nbsp;&nbsp;<img className="rounded-1 span4 center-block" src="/images/profile/vgeenclock.png" />
                          &nbsp;&nbsp;Online
                        </div>
                      </div>
                      <div className="row">
                        <div className="col font-family-Montserrat text-success-subtle fs-6 fw-normal">
                          &nbsp;&nbsp;&nbsp;&nbsp;<img className="rounded-1 span4 center-block" src="/images/profile/vbag.png" />
                          &nbsp;&nbsp;Joined {joinstmt.replace(/['"]+/g, '')} ago
                        </div>
                        <div className="col">&nbsp;</div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-md-4">
              <div className="col text-center">
                <h1 className="text-center">Profile Settings</h1>
                <p className="text-center text-success-subtle fs-6 fw-light font-family-Montserrat m-0 px-3 py-2 d-flex align-items-center justify-content-center">
                  Manage your account details and preferences.
                </p>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
          <div className="col"></div>
        </div>
      </div>
      <hr
        style={{
          color: 'rgba(256,256,256, 0.3)',
          backgroundColor: 'rgba(256,256,256, 0.3)',
          height: 1,
          width: '100%',
        }}
      />
    </>
  );
}

export default ProfileSettingsHeading;
