import { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { toast } from 'react-toastify';
import { chartColors } from './colors.js';

const Linechart = ({ initialUserData }) => {
  const [stocks, setStocks] = useState([]);
  const [currentPrices, setCurrentPrices] = useState([]);

  useEffect(() => {
    const fetchPublicUserData = async () => {
      try {
        const username = localStorage.getItem('unm');
        const res = await axios.get(Base_URL + '/portfolio/stocks', {
          params: { username: username }
        });
        setStocks(res.data);
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch data');
      }
    };

    fetchPublicUserData();
  }, []);

  useEffect(() => {
    if (stocks.length > 0) {
      fetchStockData();
    }
  }, [stocks]);

  const fetchStockData = () => {
    const symbols = stocks.map(stock => stock.ctick);
    const url = `${Base_URL_TV}/tvapimmvt?symbols=[${symbols.join(',')}]`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
       // console.log('Received tvapimmvt data:', data);
        setCurrentPrices(data[0]);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const labels = ["3 Months ago", "Month ago", "Week ago", "Today"];
// const labels = ["3 Months ago gain (T-90 Days)", "Month ago gain (T-30 Days)", "Week ago gain (T-7 Days)", "Today gain"];

  const getDataPoints = (label, stock, matchingData) => {
    const quantity = stock.csemco_quantity;
    const avgPrice = stock.avgprice;

    switch (label) {
      case "3 Months ago":
      // console.log(`---tick--${stock.ctick}-tv90days--${matchingData?.close_prices?.cp90days}--avgPrice--${avgPrice}--quantity--${quantity}--3monthsgain--${(parseFloat(matchingData?.close_prices?.cp90days) - parseFloat(avgPrice)) * quantity}`);
        return ((parseFloat(matchingData?.close_prices?.cp90days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Month ago":
       // console.log(`---tick--${stock.ctick}-tv30days--${matchingData?.close_prices?.cp30days}--avgPrice--${avgPrice}--quantity--${quantity}--monthlygain--${(parseFloat(matchingData?.close_prices?.cp30days) - parseFloat(avgPrice)) * quantity}`);
        return ((parseFloat(matchingData?.close_prices?.cp30days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Week ago":
       // console.log(`---tick--${stock.ctick}-tv7days--${matchingData?.close_prices?.cp7days}--avgPrice--${avgPrice}--quantity--${quantity}--weeklygain--${(parseFloat(matchingData?.close_prices?.cp7days) - parseFloat(avgPrice)) * quantity}`);
        return ((parseFloat(matchingData?.close_prices?.cp7days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Today":
       //console.log(`---tick--${stock.ctick}-tvtoday--${matchingData?.close_prices?.cptoday}--avgPrice--${avgPrice}--quantity--${quantity}--todaygain--${(parseFloat(matchingData?.close_prices?.cptoday) - parseFloat(avgPrice)) * quantity}`);
        return ((parseFloat(matchingData?.close_prices?.cptoday) - parseFloat(avgPrice)) * quantity).toFixed(2);
      default:
        return 0;
    }
  };

  const data = labels.map((label) => {
    const dataPoint = { label };
    stocks.forEach(stock => {
      const matchingData = currentPrices.find(item => item.symbol === stock.ctick);
      if (matchingData) {
        dataPoint[stock.ctick] = getDataPoints(label, stock, matchingData);
      }
    });
    return dataPoint;
  });

  //console.log("Final data for chart: ", data);
 
  return (
    <>
    <p className="text-white fs-6 fw-bold font-family-Montserrat">
    Gain Time Series Chart
</p>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" interval={0} angle={0} padding={{ left: 30, right: 30 }} textAnchor="middle" dx={-5} dy={5} />
        <YAxis/>
        <Tooltip />
        <Legend />
        {stocks.map((stock, index) => (
          <Line
            key={stock.ctick}
            dataKey={stock.ctick}
            name={stock.ctick}
            type="monotone"
            stroke={chartColors[index % chartColors.length]}
            dot={false} // Disable dots on the line
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
    </>
  );
};

export default Linechart;
