import * as React from 'react';
import './Portfolio.css'
import {Routes, Route, useNavigate} from 'react-router-dom';
import ImageDisplay from '../../page/ImageDisplayprofile';
import { calcDate } from '../calcDate'; // Adjust the path if needed
import moment from 'moment';

function PortfolioHeading() {
  const username = localStorage.getItem('unm');
  const userid = localStorage.getItem('userid');
  const crdate = localStorage.getItem('crdate');
  const today = new Date();

   // Format dates for calcDate function
   const formattedToday = moment(today).format('MM-DD-YYYY');
   const formattedCrDate = moment(crdate).format('MM-DD-YYYY');
 
   // Calculate date difference using calcDate
   const dateDifference = calcDate(formattedToday, formattedCrDate);

      return (
        <>

<div class="container">
<div className="row align-items-start bg-black  text-white centered-text-profilesettings">
<div class="p-4"></div>
<div className="row">
<div className="col-md-4">
    <div className="col" id="CSEMaverick">    
    <table className="table-borderless  bg-black">      <tr>
        <td className="d-flex justify-content-start" colSpan="2"  id="CSEMaverick">CSEMaverick</td></tr>
        <tr>
        <td  colSpan="2"></td>&nbsp;</tr>
       <tr>      
        <td className="d-flex justify-content-start border-0">
        <ImageDisplay imageId={userid}/>
        </td>
        <td className="justify-content-start  text-nowrap">
        <div class="row">
        <div class="col font-family-Montserrat text-success-subtle fs-6 fw-normal"><img class="rounded-1 span4 center-block" src="/images/profile/vgeenclock.png"/>&nbsp;&nbsp;Online</div>
      
      </div>
      <div class="row">
        <div class="col font-family-Montserrat text-success-subtle fs-6 fw-normal"><img class="rounded-1 span4 center-block" src="/images/profile/vbag.png"/>&nbsp;&nbsp;Joined {dateDifference.result} ago</div>
        <div class="col">&nbsp;</div>
      </div>
        </td>
    </tr>

</table>
</div>
</div>
<div className="col-md-4">
<div className="col text-center">
    <h1 className="text-center text-nowrap">TrendRunner Portfolio Analytics</h1>
    <p class="text-center  m-0 px-0 py-0  text-nowrap">
      <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">Track your investments</span>
      <span class="text-success-subtle fs-6 fw-light font-family-Montserrat">,</span>
      <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat"> assess risk</span>
      <span class="text-success-subtle fs-6 fw-light font-family-Montserrat">, and </span>
      <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">make informed decisions</span>
      <span class="text-success-subtle fs-6 fw-light font-family-Montserrat"> with our </span>
      </p>

      <p class="text-center  m-0 px-0 py-0   text-nowrap">
      <span class="text-success-subtle fs-6 fw-light font-family-Montserrat"> portfolio analysis tools. </span>
      <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">Visualize your portfolio's performance</span>
      <span class="text-success-subtle fs-6 fw-light font-family-Montserrat"> with intuitive charts </span>
      </p>
      <p class="text-center  m-0 px-0 py-0   text-nowrap">
      <span class="text-success-subtle fs-6 fw-light font-family-Montserrat"> and metrics, empowering you to </span>
      <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">optimize your investments</span>
      <span class="text-success-subtle fs-6 fw-light font-family-Montserrat"> and </span>
      <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat">achieve your</span>
      </p>
      <p class="text-center  m-0 px-0 py-0   text-nowrap">
      <span class="text-success-subtle fs-6 fw-bold font-family-Montserrat"> goals with confidence.</span>
      </p>
   </div>
  </div>
  <div className="col-md-4"></div>
    </div>
  
    <div className="col">
   
    </div>
  </div>
  </div>
        
          </>
    );
}

export default PortfolioHeading;