import React from 'react';


function CarouselContent({ CarouselHeader, CarouselContent, src }) {
    return (
        <div className='d-flex'>
            <div className="d-block w-50 justify-content-center align-items-center text-white">
                <div className='m-5 centered-text'>
                    <h1 style={{whiteSpace: 'nowrap'}}>{CarouselHeader}</h1>
                    <div className='w-100 d-block justify-content-center align-items-center text-white'>
                    <h6>{CarouselContent}</h6>
                </div>
                </div>
              
            </div>
            <div className="d-block w-50 d-flex justify-content-center align-items-center p-4 m-4 radial-brightness">
                <img src={src} alt={CarouselHeader} style={{ filter: 'brightness(75%)',borderRadius: '20px',width: '325px',height: '354px',flexshrink: '0'
 }} />
            </div>
        </div>
    );
}

export default CarouselContent;