// export default UserRegister;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext"; // Import useAuth
import "./login.css"; // Import specific CSS for Login
import { Base_URL } from "../../Components/BaseURL";
import {Routes, Route, useNavigate} from 'react-router-dom';
import {ToastContainer,toast} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import Home from '../../Home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faUser, faLock, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
function UserRegister() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login from context
  const [regDone, setRegDone] = useState(false)
 const history = useNavigate(); //for dinamically changing the path
 const [data, setData] = useState({
  username: "",
  password: ""
});
 useEffect(() => {
   document.title = "CSE System - LogIn Page"; //dinamically changes the tittle
 });
 const handleChange = (e) => {
  const value = e.target.value;
  setData({
    ...data,
    [e.target.name]: value
  });
};
 //login function with axios
 const HandleRegister = async (e) => {
   e.preventDefault(); //disables the reload on submission
   localStorage.setItem('unm',data.username);
   const userData = {
    username: data.username,
    password: data.password
  };
   try {
     //check if user has filled all required fields
     if (!data.username || data.username === "" || !data.password || data.password === "") {
      toast.warning("All Fields are Required");
      return;
    }
    axios.post(`${Base_URL}/auth/signup`, userData)
    .then(async (response) => {
      console.log(response);
// existing user
      if (response.status === 200) {
     //token will be send from server as a response which will be called token
     const token = await response.data.token;
     localStorage.setItem("token", token); //saving the token in the local storage     
     //after 3sec redirect the user to / route which is the protected route
     toast.success("LogIn Sucessful, Redirecting...");
       navigate("/");
      }
// existing user
if (response.status === 409) {
  toast.warning("User with the same username already exists...");
    navigate("/login");
   }

   if (response.status === 401) {
    toast.warning("Incorrect Password...");
     }
      if (response.status === 200) {
        //token will be send from server as a response which will be called token
        const token = await response.data.token;
        localStorage.setItem("token", token); //saving the token in the local storage     
        //after 3sec redirect the user to / route which is the protected route
        toast.success("LogIn Sucessful, Redirecting...");
          navigate("/");
         }
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        console.log("server responded");
      } else if (error.request) {
        console.log("network error");
      } else {
        console.log(error);
      }
    });
  
   } catch (error) {
     //incase of error
     console.error("Error User with the same username already exists: ", error);
     toast.error("Error User with the same username already exists");
   }
 };
 const navigateToLoginPage = () => {
    navigate('/login');
 }
  function handleClick() {
    navigate("/home");
  }
  return (
    <div className="Profilesettings p-3 mb-2 mt-5 bg-black text-white">
      <div className="container mt-1">
        <div className="bg-black row centered-element">
          <div className="bg-black col-xxl-6">
            <video
              className="videoTag"
              autoPlay
              loop
              muted
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
              <source src="/videos/login.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="col-xxl-6 row flex-column align-items-center ml-20 bg-black min-vh-100">
            <div className="input-group mb-3 flex-column align-items-center" style={{ top: '20px', textAlign: 'center', width: '50%',background: 'linear-gradient(to bottom right, black, #282727)',background: 'linear-gradient(to bottom, transparent 1%, black 100%)' }}>
              <div className="col-12 justify-content-center align-items-center" style={{ textAlign: 'center' }}>
                <div class="justify-center items-center inline-flex">
  
</div>
<a href="/"> <img src="/images/login/logo-05-10.png" class="mx-auto d-block" onClick={handleClick} alt="Logo" width="150" style={{ textAlign: 'center' }}/> </a>
              </div>
              <div class="container mt-3"></div>
              <form onSubmit={HandleRegister} className='login-form'>
                <div className="input-group mb-15">
                  <div className="error-setup text-nowrap"><p id='err' className='error-message'></p></div>
                </div>
                <div class="container mt-3"></div>
                <div class="LogIn w-80 h-5 text-center text-neutral-300 text-3xl font-bold font-['Montserrat']">Sign Up</div>
                <div className="text-center col-12 m-0 px-0 py-0 text-white">
                <div class="container mt-3"></div>
                  <span className="text-success-subtle fs-6 fw-light font-family-Montserrat">Sign up to</span>
                  <span className="text-success-subtle fs-6 fw-bold font-family-Montserrat">&nbsp;access your account</span>
                  <span className="text-success-subtle fs-6 fw-light font-family-Montserrat">&nbsp;and</span>
                  <span className="text-success-subtle fs-6 fw-bold font-family-Montserrat">&nbsp;unlock a world of powerful tools and insights</span>
                  <span className="text-success-subtle fs-6 fw-light font-family-Montserrat">&nbsp;for your stock market journey.</span>
                </div>
                <div class="container mt-3"></div>
                <div className="form-outline mb-2">
                  <div className="px-1 py-1 rounded-3 border border-1 border-white border-opacity-50 col-xxl-12 row align-items-center d-flex">
                    <input
                      type="email"
                      id="username"
                      placeholder="Email Address"
                      name="username"
                      onChange={handleChange}
                      className="form-control w-60 text-white bg-black border border-0 no-border fw-light"
                    />
                  </div>
                </div>
                             <div className='mb-3'>
                <div className="px-1 py-1 rounded-3 border border-1 border-white border-opacity-50 col-xxl-12 row align-items-center d-flex">
                        <div className='input-group d-flex align-items-center p-0'>
                             <input
                      type={showPassword ? "text" : "password"}
                      className="form-control w-40 text-white bg-black border border-0 no-border fw-light"
                      id="password"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                    />
                            <button
                                className='btn btn-outline-secondary'
                                type='button'
                                onClick={()=>setShowPassword(!showPassword)}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className='me-2' />
                            </button>
                        </div>
                        </div>
                    </div>
                <div className="input-group mb-3">
                  <span className="text-white text-opacity-50 fs-6 fw-semibold font-family-Montserrat">
                    Forgot Password? <a href="/">Click here</a>
                  </span>
                </div>
                <div className="input-group mb-3">
                  <div  className="px-1 py-1 w-80 bg-success-subtle rounded-3 border border-1 border-white border-opacity-50 col-xxl-11 justify-content-center align-items-center d-flex">
                    <button type="submit" class="mx-auto d-block" className="btn btn-block mt-0 p-0 text-dark">Sign Up</button>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <div className="px-1 py-1 w-80 bg-success-subtle rounded-3 border border-1 border-white border-opacity-50 col-xxl-11 justify-content-center align-items-center d-flex">
                  <FontAwesomeIcon className='btn btn-danger' icon={faGoogle}/>
                    <button type="submit" className="btn btn-block mt-0 p-0 text-dark">Sign up with Google</button>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <div className="px-1 py-1 w-80 bg-success-subtle rounded-3 border border-1 border-white border-opacity-50 col-xxl-11 justify-content-center align-items-center d-flex">
                  <FontAwesomeIcon className='btn btn-info' icon={faApple} />
                    <button type="submit" className="btn btn-block mt-0 p-0 text-dark">Sign up with Apple</button>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <span className="text-white text-opacity-50 fs-6 fw-semibold font-family-Montserrat align-items-center d-flex">
                  Already have an account?&nbsp;&nbsp; <button type="button" onClick={navigateToLoginPage} className="btn btn-block mt-0 p-0 text-white btn-link">Log In</button>
                    <ToastContainer />
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserRegister;



