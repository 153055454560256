import React from 'react';
import './about-us.css'
import SectionOne from './sectionOne';
import SectionTwo from './sectionTwo';
import SectionThree from './sectionThree';
import SectionFour from './sectionFour';


function AboutUs() {
    return (
        <>
<div class="layout">
  <div class="p-1"></div>
  <header>
  <SectionOne />
   </header>
  <main class="content">
  <div class="modal-body row">
  <div class="col-md-12">
  <SectionTwo />
  <SectionThree />
  </div>
</div>
  
  </main>
  <footer>
  <SectionFour />
   </footer>
</div> 
</>

    );
}

export default AboutUs;