// PortfolioCreation.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from '../../Components/BaseURL.js';
import { AdvancedChart } from "react-tradingview-embed";
const PortfolioCreation = ({ updateData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const username = localStorage.getItem('unm');
  const [formDataStocks, setFormDataStocks] = useState({
    InputStock: "",
    InputQty: "",
    InputAvgPrice: "",
    username: localStorage.getItem('unm')
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        //const stockRes = await axios.get(Base_URL + `/masterdata/stocks`);
        const stockRes = await axios.get(Base_URL + `/masterdata/stocks`, {
          params: { username: username }
        });
        setData(stockRes.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to load data");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Update form data when updateData is provided
    if (updateData) {
      setFormDataStocks({
        InputStock: updateData.id,
        InputQty: updateData.qty,
        InputAvgPrice: updateData.avgp,
        username: localStorage.getItem('unm')
      });
    }
  }, [updateData]);

  const postStockData = async (formDataStocks) => {
    try {
      const res = await axios.post(Base_URL + `/portfolio/poststock`, formDataStocks);
      toast.success("Stock Data Successfully inserted..");
      window.location.reload();
    } catch (error) {
      toast.error("Error submitting data. Please try again later.");
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postStockData(formDataStocks);
  };

  return (
    <>
      <div id="component" className="container-fluid py-3">
        <ToastContainer />
        <p className="text-white fs-6 fw-bold font-family-Montserrat">
          PORTFOLIO CREATION
        </p>
      </div>
      <form className="form-inline" onSubmit={handleSubmit}>
        <div className="row g-3 align-items-center">
          <div className="col-4">
            <p className="text-white fs-6 fw-normal font-family-Montserrat">Select a stock</p>
          </div>
          <div className="col-4">
            <select
              className="form-control w-60 text-white bg-black fw-light"
              onChange={(e) => setFormDataStocks({ ...formDataStocks, InputStock: e.target.value })}
              name="InputStock"
              value={formDataStocks.InputStock}
            >
              <option value="">Select a Stock</option>
              {data.map((stocks) => (
                <option
                  key={stocks.csem_id}
                  value={stocks.csemc_id}
                >
                  {stocks.csemc_name}
                </option>
              ))}
            </select>
          </div>
          </div>
          <div className="row g-3 align-items-center p-1">
          <div className="col-4">
            <p className="text-white fs-6 fw-normal font-family-Montserrat p-10">Enter Quantity</p>
          </div>
          <div className="col-4">
            <input
              type="number"
              className="form-control text-white bg-black fw-light w-20"
              id="InputQty"
              required
              name="InputQty"
              value={formDataStocks.InputQty}
              onChange={(e) => setFormDataStocks({ ...formDataStocks, InputQty: e.target.value })}
            />
          </div>
          </div>
          <div className="row g-3 align-items-center p-1">
          <div className="col-4">
            <p className="text-white fs-6 fw-normal font-family-Montserrat p-10">Avg. Price</p>
          </div>
           <div className="col-4">
            <input
              type="number"
              className="form-control text-white bg-black fw-light w-10"
              id="InputAvgPrice"
              required
              name="InputAvgPrice"
              value={formDataStocks.InputAvgPrice}
              onChange={(e) => setFormDataStocks({ ...formDataStocks, InputAvgPrice: e.target.value })}
            />
          </div>
          </div>
          <div className="p-0 px-20"></div>
        <div className="col-auto">
          <button type="submit" className="btn btn-dark border border-white mr-1">Add Record</button>
        </div>
      </form>
{/* <AdvancedChart/> */}
      
    </>
  );
};

export default PortfolioCreation;
