import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../../Components/BaseURL.js';
import {
  PieChart, Pie, Cell, Tooltip, ResponsiveContainer
} from 'recharts';
import '../Portfolio.css';
import { useNavigate } from "react-router-dom";
import html2canvas from 'html2canvas';
import { chartColors } from "../colors.js";
import { TwitterShareButton } from "react-share";
import domtoimage from "dom-to-image";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import moment from 'moment';
import { calcDate } from '../../calcDate'; 
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const PortfolioPerformance_menu = ({ label }) => {
    const [stocks, setStocks] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalpfsize, setTotalpfsize] = useState(0);
    const [Tots, setTots] = useState([]);
    const navigate = useNavigate();
    const [pfcompStrings, setPfcompStrings] = useState([]);
    const backgColor = chartColors;
    const [totalgain, setTotalGain] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const crdate = localStorage.getItem('crdate');
    const today = new Date();  
    const formattedToday = moment(today).format('YYYY-MM-DD');
    const formattedCrDate = moment(crdate).format('YYYY-MM-DD');
    const dateDifference = calcDate(formattedToday, formattedCrDate);

    useEffect(() => {
      const fetchPublicUserData = async () => {
        try {
          const username = localStorage.getItem('unm');
          const res = await axios.get(Base_URL + "/portfolio/stocks", {
            params: { username: username }
          });
          setStocks(res.data);
        } catch (error) {
          console.error(error);
          toast.error("Failed to fetch data");
        }
      };
  
      fetchPublicUserData();
    }, []);
  
    const downloadChartAsImage = async () => {
      const datapdf = document.getElementById('Doughnutrefid');
      const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      try {
        html2canvas(datapdf, { backgroundColor: 'black', allowTaint: true, useCORS: true }).then((canvas) => {
          var link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `portfolio_gain_${label}_${currentDate}.png`;
          link.click();
        });
      } catch (error) {
        console.error('Error downloading the chart:', error);
        toast.error('Failed to download chart');
      }
    };
  
    function navigateTo(path) {
      navigate(path);
    }
  
    function handleSelect_gain(evt) {
      navigateTo("/portfolio_gain");
    }
  
    function handleSelect_apd(evt) {
      navigateTo("/portfolio_apd");
    }
  
    function handleSelect_gs_t(evt) {
      navigateTo("/portfolio/today");
    }
  
    function handleSelect_gs_lm(evt) {
      navigateTo("/portfolio/last-month");
    }
  
    function handleSelect_gs_ltm(evt) {
      navigateTo("/portfolio/last-three-months");
    }
    function handleSelect_g_t(evt) {
      navigateTo("/portfolio_gain/today");
    }
  
    function handleSelect_g_lm(evt) {
      navigateTo("/portfolio_gain/last-month");
    }
  
    function handleSelect_g_ltm(evt) {
      navigateTo("/portfolio_gain/last-three-months");
    }

    const chartData = stocks.map((stock, index) => ({
      name: stock.cname,
      value: stock.csemco_quantity * stock.avgprice,
      tick: stock.ctick,
      color: backgColor[index]
    }));
  
    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClick2 = (event) => {
      setAnchorEl2(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleClose2 = () => {
      setAnchorEl2(null);
    };

    const handleShare = () => {
      let node = document.getElementById('Doughnutrefid');
      domtoimage.toPng(node)
        .then(dataUrl => {
          axios.post("http://127.0.0.1:3000/upload/imagetotweet", { dataUrl: dataUrl })
            .then(res => {
              const url = res.data.imageUrl;
              const twitterURL = `https://twitter.com/share?url=${url}&text=Check%20out%20this%20image&hashtags=yourHashtags`;
              window.open(twitterURL, "twitter");
            })
            .catch(err => console.log(err, "Error trying to tweet"));
        })
        .catch(err => console.log(err));
    };

  return (
    <>
      <div className="d-grid gap-2 d-md-flex justify-content-md-center">
        <Button
          className="btn btn-secondary me-md-2 basic-button text-capitalize font-Montserrat fs-6"
          onClick={handleMenuClick}
        >
          Gain&nbsp;&nbsp;&nbsp;<ArrowDropDown />
        </Button>

        <Menu
          id="basic-menu"
          className="btn-secondary"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              navigateTo("/portfolio");
              handleClose();
            }}
            className="btn-secondary"
          >
            Gain snapshot
          </MenuItem>
          <MenuItem
            onClick={handleSelect_apd}
            className="menu-item"
          >
            Annual Projected Dividend
          </MenuItem>
        </Menu>

        <Button
          className="btn btn-secondary me-md-2 basic-button text-capitalize font-Montserrat fs-6"
          onClick={handleMenuClick2}
        >
          Till Date&nbsp;&nbsp;&nbsp;<ArrowDropDown />
        </Button>

        <Menu
          id="basic-menu2"
          className="btn-secondary"
          anchorEl={anchorEl2}
          open={Boolean(anchorEl2)}
          onClose={handleClose2}
        >
     <MenuItem onClick={handleSelect_g_t} className="menu-item">Till Today</MenuItem>
            {JSON.stringify(dateDifference.total_days) > 30  && (            
            <MenuItem onClick={handleSelect_g_lm} className="menu-item">Last Month</MenuItem>
            )}
             {JSON.stringify(dateDifference.total_days) > 90  && ( 
            <MenuItem onClick={handleSelect_g_ltm} className="menu-item">Last Three Months</MenuItem>
          )}
        </Menu>

        <TwitterShareButton
          url={"http://127.0.0.1:3000"}
          title="Hey, I just shared my stocks"
          hashtags={["hashtag1", "hashtag2"]}
          onClick={handleShare}
          className="font-family-Montserrat  me-md-2"
        >
          <i className="btn btn-secondary bi bi-twitter-x font-family-Montserrat fs-6" style={{ fontStyle: 'normal' }}>
            &nbsp;&nbsp;&nbsp;Share
          </i>
        </TwitterShareButton>
        <button className="btn btn-secondary bi bi-download font-family-Montserrat fs-6" type="button" onClick={downloadChartAsImage}>
          &nbsp;&nbsp;&nbsp;Download
        </button>
      </div>
    </>
  );
};

export default PortfolioPerformance_menu;
