import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import MUIDataTable from "mui-datatables";
import "bootstrap-icons/font/bootstrap-icons.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DOMPurify from 'dompurify';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
const CSEnews = () => {
      const [csenews, setCseNews] = useState([]);
      const [open, setOpen] = React.useState(false);
      const [data, setData] = useState(null);
      const handleClickOpen = (value) => {
        setData(value);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };





    useEffect(() => {
        const fetchFiniData = async () => {
            try {
                const username = localStorage.getItem('unm');
                const res = await axios.get(Base_URL + "/news/getnews", {
                    params: { username: username }
                });
                console.log(res.data);
                setCseNews(res.data);
            } catch (error) {
                console.error(error);
                toast.error("Failed to fetch data");
            }
        };

        fetchFiniData();
    }, []);

    const columns = [
        {
            name: "sector",
            label: "Sector",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "news",
            label: "News",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                  return (
                    <a href="#" onClick={() => handleClickOpen(value)}>{value.substring(0,200)}....</a>
                    // <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} onClick={() => handleClickOpen(value)} />
                  );
            }
          }
        },
    ];

    const options = {
        filter: true,
        selectableRows: false,
        search: true,
        download: false,
        print: false,
        responsive: "standard",
        rowsPerPageOptions: [5, 10, 15],
        rowsPerPage: 5,
    };
    // const getMuiTheme = () =>
    //   createTheme({
    //     components: {
    //       MuiTableCell: {
    //         fontcolor:'#ffffff',
    //         styleOverrides:{ root: {
    //          backgroundColor: '#000000',
    //          fontcolor:'#ffffff'
    //         }}
    //       },
    //       MuiToolbar: {
    //         styleOverrides:{regular: {
    //           backgroundColor: '#000000',
    //            fontcolor:'#ffffff'
    //         }}
    //       }
    //     }
    //   });
  
      const getMuiTheme = () =>
        createTheme({
         palette: {
           mode: 'dark',
           backgroundColor: '#000000',
         },
         components: {
           MuiLink: {
             styleOverrides: {
               root: {
                backgroundColor: '#000000',
               }
             }
           },
           MuiTableCell: {
                    fontcolor:'#ffffff',
                    styleOverrides:{ root: {
                     backgroundColor: '#000000',
                     fontcolor:'#ffffff'
                    }}
                  },
           MUIDataTableToolbar: {
             styleOverrides: {
               actions: {
                 display: 'flex',
                 flex: 'initial',
                 '& > span, & > button': {
                   order: 99
                 },
                 '& > span:last-child, & > button:last-child': {
                   order: 1
                 },
                 '& > span:nth-child(4), & > button:nth-child(4)': {
                   order: 2
                 }
               }
             }
           }
         }
       });
    return (
        <>

<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
     
                <DialogTitle id="alert-dialog-title">
          {"News"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
            <p className="text-white fs-6 fw-bold font-family-Montserrat">
                NEWS
            </p>

            <div id="component" className="container-fluid py-3">
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable class="table-dark mytable"
                     data={csenews}
                    columns={columns}
                    options={options}
                              />
                   </ThemeProvider>
                
            </div>
        </>
    );

};

export default CSEnews;
