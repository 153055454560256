import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from '../../Components/BaseURL.js';
import EditDialogPW from "./EditDialogPWChange.js";
import EditDialogEmail from "./EditDialogMailChange.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faUser, faLock, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
const Profile_Private = ({ initialUserData }) => {
  const [data, setData] = useState([]);

  const [updateData, setUpdateData] = useState(null);
  const [editDialogPWOpen, setEditDialogPWOpen] = useState(false);
  const [editDialogEmailOpen, setEditDialogEmailOpen] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [showPassword , setshowPassword ] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null); 
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const [formDataUserPrivate, setformDataUserPrivate] = useState({
    InputUserId: "",
    InputEmail: "",
    InputPassword:"",
    InputFirstName: "",
    InputLastName: "",
    InputPhone: "",
    InputAltEmail: "",
  });
  const [password, setPassword] = useState("");
  const URL = Base_URL;
  const username = localStorage.getItem('unm');
  useEffect(() => {
    fetchPrivateUserData(); // Fetch public user data when the component mounts
  }, []);
  const fetchPrivateUserData = async () => {
    try {
      //const res = await axios.get(Base_URL + "/userGetPrivate");
      const res = await axios.get(`${URL}/profile/profileprvget`, {
        params: { username: username }
      });
      if (res.data.length > 0) {
        const userDataPrivate = res.data[0];
        const initialData = {
          InputUserId: userDataPrivate.scsemppri_id,
          InputEmail:userDataPrivate.scsemppri_email,
          InputPassword:userDataPrivate.scsemppri_password,
          InputFirstName: userDataPrivate.scsemppri_first_name,
          InputLastName: userDataPrivate.scsemppri_last_name,
          InputPhone: userDataPrivate.scsemppri_phone,
          InputAltEmail:  userDataPrivate.scsemppri_alt_email  
        };
        setformDataUserPrivate(initialData);
        setInitialFormData(initialData); 
         
      }
    } catch (error) {
      console.error(error);
    }
  };
  //post data
  const postUserData = async (formDataUserPrivate) => {
    try {
      const res = await axios.post(Base_URL + "/profile/profileprvpost", formDataUserPrivate);
      toast.success("Private Data Successfully updated.");
      await delay(1000);
      window.location.reload();
      // Fetch data again after posting if needed
    } catch (error) {
      toast.error("Error submitting data. Please try again later.");
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setformDataUserPrivate({ ...formDataUserPrivate, [e.target.name]: e.target.value });
  };

  // const handleSubmit = (e) => {
     const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(JSON.stringify(initialFormData) +'==='+ JSON.stringify(formDataUserPrivate))
      if (JSON.stringify(initialFormData) === JSON.stringify(formDataUserPrivate)) {
        toast.info("No changes detected, submission prevented.");
        return;  // Exit the function if no changes were made
      }
     postUserData(formDataUserPrivate);
  };
  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: 'rgba(256,256,256, 0.3)',
            backgroundColor: 'rgba(256,256,256, 0.3)',
            height: 1,
            width: '100%'
        }}
    />
);


const handleEmailChgModal = (data) => {
  setUpdateData(data);
  setEditDialogEmailOpen(true);
};

const handlePWResetModal = (data) => {
  setUpdateData(data);
  setEditDialogPWOpen(true);
};
const closeEditEmailDialog = () => {
  setEditDialogEmailOpen(false);
  setUpdateData(null);
};
const closeEditPWDialog = () => {
  setEditDialogPWOpen(false);
  setUpdateData(null);
};
  return (
    <>
    <div className="col-md-12 bg-light bg-black text-white w-100">
      <ToastContainer />
         <form id="formprivate" className="form-horizontal" onSubmit={handleSubmit}>
                  <legend>
          <span className=" d-flex justify-content-end">
            <b>PRIVATE DETAILS</b>
          </span>
          <h6 className="mt-1 mb-1 d-flex justify-content-end">
            <small>
          Note: This information will not be displayed in public.
          </small>
          </h6>
        </legend>
         <ColoredLine/>
  <div className="row row-cols-3 pt-3">
    <div className="col text-end">
      <label htmlFor="nameVariable" className="form-label">Email</label>
    </div>
    <div className="col-8 d-grid gap-2 d-md-flex container flex-grow">
    <input
              type="email" 
              className="form-control text-white bg-black fw-light text-nowrap p-0 flex-grow"
              id="InputEmail"
              readOnly
              name="InputEmail"
              value={formDataUserPrivate.InputEmail}
              placeholder="Email"
              onChange={(e) =>
                setformDataUserPrivate({ ...formDataUserPrivate, InputEmail: e.target.value })
              }
              
            />
    <button type="button"  className="btn btn-secondary btn-sm active text-nowrap w-30" onClick={() => handleEmailChgModal(formDataUserPrivate)} id="changeemail">
              Change Email
            </button>
    </div>
  </div>
  <div className="row row-cols-3 pt-3">
    <div className="col text-end">
      <label htmlFor="nameVariable" className="form-label">Password</label>
    </div>
    <div className="col-8 d-grid gap-2 d-md-flex container flex-grow">
    <input
              type={showPassword ? "text" : "password"}
              className="form-control w-80 text-white bg-black fw-light text-nowrap p-0 flex-grow"
              id="InputEmail"
              readOnly
              name="InputPassword"
              value={formDataUserPrivate.InputPassword}
              placeholder="Password"
              // onChange={(e) =>
              //   setformDataUserPrivate({ ...formDataUserPrivate, InputPassword: e.target.value })
              // }
              onChange={(e) => setPassword(e.target.value)}
            />

    <button type="button" onClick={() => handlePWResetModal(formDataUserPrivate)} className="btn btn-secondary btn-sm active text-nowrap w-40" id="changeemail">
              Change Password
            </button>
            {/* <button
                                className='btn btn-outline-secondary'
                                type='button'
                                 onClick={()=>setshowPassword(!showPassword)}
                            >
 <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className='me-0' /> 
                            </button> */}
    </div>
  </div>
  <div className="row row-cols-3 pt-3">
  <div className="col text-end">
    First Name
    </div>
    <div className="col text-start col-8">
    <input
              type="text"
               className="form-control w-40 text-white bg-black fw-light"
              id="InputFirstName"
              name="InputFirstName"
              value={formDataUserPrivate.InputFirstName}
              placeholder="First Name"
              onChange={(e) =>
                setformDataUserPrivate({ ...formDataUserPrivate, InputFirstName: e.target.value })
              }
            />
    </div>
  </div>
  <div className="row row-cols-3 pt-3">
  <div className="col text-end">
    Last Name
    </div>
    <div className="col text-start col-8">
    <input
              type="text"  
              className="form-control w-40 text-white bg-black fw-light"
              id="InputLastName"
              name="InputLastName"
              value={formDataUserPrivate.InputLastName}
              placeholder="Last Name"
              onChange={(e) =>
                setformDataUserPrivate({ ...formDataUserPrivate, InputLastName: e.target.value })
              }
            />
    </div>
  </div>

  <div className="row row-cols-3 pt-3">
  <div className="col text-end">
    Phone
    </div>
    <div className="col text-start col-8">
    <input
              type="text"  
              className="form-control w-40 text-white bg-black fw-light"
              id="InputPhone"
              name="InputPhone"
              value={formDataUserPrivate.InputPhone}
              placeholder="Phone"
              onChange={(e) =>setformDataUserPrivate({ ...formDataUserPrivate, InputPhone: e.target.value })
              }
            />
    </div>
  </div>
  <div className="row row-cols-3 pt-3">
    <div className="col text-end  text-nowrap">
      <label htmlFor="nameVariable" id="altEmail" className="form-label text-nowrap">Email for alerts&nbsp;</label>
    </div>
    <div className="col-8 d-grid gap-2 d-md-flex container flex-grow">
    <input
              type="email" 
              className="form-control w-40 p-0 m-0 text-white bg-black fw-light"
              id="InputEmail"
              name="InputAltEmail"
              value={formDataUserPrivate.InputAltEmail}
              placeholder="Alternative Email for alerts"
              onChange={(e) =>
                setformDataUserPrivate({ ...formDataUserPrivate, InputAltEmail: e.target.value })
              }
            />
     <small className="text-nowrap" style={{ fontSize: '0.6875em'}}>Email address that can be used<br /> to send alerts.</small>
    </div> 
  </div>



  <input
              type="hidden"
              id="InputUserId"
              name="InputUserId"
              value={formDataUserPrivate.InputUserId}
              onChange={(e) =>
                setformDataUserPrivate({ ...formDataUserPrivate, InputUserId: e.target.value })
              }
            />
  <div className="row row-cols-3 pt-3">
    <div className="col" />
    <div className="col text-start  text-nowrap">
      <button type="submit" className="btn btn-secondary">Save Changes</button>
    </div>
  </div>

  </form>
    </div>

    <EditDialogEmail
        open={editDialogEmailOpen}
        onClose={closeEditEmailDialog}
        stockData={updateData}
        onUpdate={(updatedStock) => {
          setStocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
        }}
         />

<EditDialogPW
        open={editDialogPWOpen}
        onClose={closeEditPWDialog}
        stockData={updateData}
        onUpdate={(updatedStock) => {
          setStocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
        }}
         /> 
         
    </>
  );
};

export default Profile_Private;
