import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext"; // Import useAuth
import "./login.css"; // Import specific CSS for Login
import { Base_URL } from "../../Components/BaseURL";
import {useNavigate} from 'react-router-dom';
import {ToastContainer,toast} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
function Login() {
  const [showPassword , setshowPassword ] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login from context
 const [username, setUsername] = useState("");
 const [password, setPassword] = useState("");
 useEffect(() => {
   document.title = "CSE System - LogIn Page"; //dinamically changes the tittle
 });

const handleLogin = async (e) => {
  e.preventDefault();
  
  try {
    if (!username || username === "" || !password || password === "") {
      toast.warning("All Fields are Required");
      return;
    }
    const response = await axios.post(`${Base_URL}/auth/login`, {
      username: username,
      password: password
    }, {
      withCredentials: true, // ensures that cookies (like JWT tokens) are sent along with requests
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      const { token, userid ,crdate } = response.data; // Destructure token and userid from response
      console.log('--unm--'+username); 
      localStorage.setItem('unm', username); // Store username
      localStorage.setItem('token', token); // Store token
      localStorage.setItem('userid', userid); // Store user ID
      localStorage.setItem('crdate',crdate);

      setUsername(""); // Clear username
      setPassword(""); // Clear password
      
      login({ token, userid }); // Pass token and userid to the context if needed
      
      navigate("/");
      toast.success("LogIn Successful, Redirecting...");
    }

  } catch (error) {
    console.error("Error Logging User: ", error);
    toast.error("Error Logging User");
  }
};

  const navigateToSigninPage = () => {
    // 👇️ Navigate to /login
    navigate('/userregister');
  };
  function handleClick() {
    navigate("/home");
  }

  return (
    <div className="Profilesettings p-3 mb-2 mt-5 bg-black text-white">
      <div className="container mt-1">
        <div className="bg-black row centered-element">
          <div className="bg-black col-xxl-6">
            <video
              className="videoTag"
              autoPlay
              loop
              muted
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
              <source src="/videos/login.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="col-xxl-6 row flex-column align-items-center ml-20 bg-black min-vh-100">
            <div className="input-group mb-3 flex-column align-items-center" style={{ top: '20px', textAlign: 'center', width: '100%',background: 'linear-gradient(to bottom, transparent 1%, black 100%)' }}>
              <div className="col-12 justify-content-center align-items-center" style={{ textAlign: 'center' }}>
                <div class="justify-center items-center inline-flex">
  
</div>
<a href="/"> 
<img src="/images/login/logo-05-10.png" class="mx-auto d-block" onClick={handleClick} alt="Logo" width="150" style={{ textAlign: 'center' }}/> </a>
              </div>
              <div class="container mt-3"></div>
              <form onSubmit={handleLogin} className='login-form' style={{width: '50%'}}>
                <div className="input-group mb-15">
                  <div className="error-setup text-nowrap"><p id='err' className='error-message'></p></div>
                </div>
                <div class="container mt-3"></div>
                <div class="LogIn w-80 h-5 text-center text-neutral-300 text-3xl font-bold font-['Montserrat']">Log In</div>
                <div class="container mt-3"></div>
                <div className="form-outline mb-2">
                  <div className="px-1 py-1 rounded-3 border border-1 border-white border-opacity-50 col-xxl-12 row align-items-center d-flex">
                    <input
                      type="email"
                      id="username"
                      placeholder="Email Address"
                      name="username"
                      onChange={(evt) => setUsername(evt.target.value)}
                      className="form-control w-60 text-white bg-black border border-0 no-border fw-light"
                    />
                  </div>
                </div>
                 <div className='form-outline mb-3'>
                 <div className="px-1 py-1 rounded-3 border border-1 border-white border-opacity-50 col-xxl-12 row align-items-center d-flex">
                 <div className='input-group d-flex align-items-center p-0'>
                             <input
                      type={showPassword ? "text" : "password"}
                      className="form-control w-60 text-white bg-black border border-0 no-border fw-light"
                      id="password"
                      placeholder="Password"
                      name="password"
                      onChange={(evt) => setPassword(evt.target.value)}
                    />
                            <button
                                className='btn btn-outline-secondary'
                                type='button'
                                 onClick={()=>setshowPassword(!showPassword)}
                            >
 <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className='me-2' /> 
                            </button>
                        </div>
                        </div>
                    </div>
                <div className="input-group mb-3">
                  <span className="text-white text-opacity-50 fs-6 fw-semibold font-family-Montserrat">
                    Forgot Password? <a href="/">Click here</a>
                  </span>
                </div>
                <div className="input-group mb-3">
                  <div  className="px-1 py-1 w-80 bg-success-subtle rounded-3 border border-1 border-white border-opacity-50 col-xxl-11 justify-content-center  align-items-center d-flex">
                    <button type="submit" class="mx-auto d-block" className="btn btn-block mt-0 p-0 text-dark">Log In</button>
                  </div>
                </div>
                <hr class="hr-text gradient" data-content="OR"/>
                <div className="input-group mb-3">
                  <div className="px-1 py-1 w-80 bg-success-subtle rounded-3 border border-1 border-white border-opacity-50 col-xxl-11 justify-content-center  align-items-center d-flex">
                  <FontAwesomeIcon className='btn btn-danger' icon={faGoogle}/>
                    <button type="submit" className="btn btn-block mt-0 p-0 text-dark">Sign up with Google</button>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <div className="px-1 py-1 w-80 bg-success-subtle rounded-3 border border-1 border-white border-opacity-50 col-xxl-11 justify-content-center align-items-center d-flex">
                  <FontAwesomeIcon className='btn btn-info' icon={faApple} />
                    <button type="submit" className="btn btn-block mt-0 p-0 text-dark">Sign up with Apple</button>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <span className="text-white text-opacity-50 fs-6 fw-semibold font-family-Montserrat align-items-center d-flex">
                    Don’t have an account?&nbsp;&nbsp;<button type="button" onClick={navigateToSigninPage} className="btn btn-block mt-0 p-0 text-white">Sign In</button>
                    <ToastContainer />
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;



