// export default Portfolio;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import PortfolioHeading from './PortfolioHeading;';
import CurrentPortfolio from './CurrentPortfolio';
import PortfolioCreation from './PortfolioCreation';
import Todayspricevolume from './Todayspricevolume';
import Linechart from './linechart';
import Finacialinfo from './FinancialInfo.js';
import CSENEWS from './CSENews.js';
import DividentTracker  from './DividentTracker.js';
import Marketsentiment from './MarketSentiment.js';
import './Portfolio.css';
import RESEARCHInfo from './ResearchInfo.js';
function Portfolio() {
  const location = useLocation();
  const [currentPageName, setCurrentPageName] = useState('');

  useEffect(() => {
    const updatePageName = () => {
      const pathname = location.pathname;
      switch (pathname) {
        case '/portfolio':
          setCurrentPageName('PortfolioPerformance');
          break;
        case '/portfolio/today':
          setCurrentPageName('PortfolioPerformanceToday');
          break;
        case '/portfolio/last-month':
          setCurrentPageName('PortfolioPerformanceLMonth');
          break;
        case '/portfolio/last-three-months':
          setCurrentPageName('PortfolioPerformanceLThreeM');
          break;
        default:
          setCurrentPageName(null);
      }
    };

    updatePageName();
  }, [location.pathname]);
  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: 'rgba(256,256,256, 0.3)',
            backgroundColor: 'rgba(256,256,256, 0.3)',
            height: 1
        }}
    />
);
  return (
    <>
      <div className="layout fluid">
      <header class="header-area header-sticky">
      <div class="p-4"></div>
          <PortfolioHeading />
        </header>
        <main className="content">
          <div className="row  h-100 justify-content-center align-items-center">
      
            <div className="col-md-12">
              <Outlet />
            </div>
     
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-8">
              <CurrentPortfolio />
            </div>
            <div className="col-md-4">
              <PortfolioCreation />
            </div>
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-6">
              <Todayspricevolume />
            </div>
            {/* <div className="col-md-6">

              <Linechart/>
                    </div> */}
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-12">
            <Finacialinfo/>
            </div>
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-12">
            <CSENEWS/>
            </div>
          </div>
          <ColoredLine/>
          <div className="row">
            <div className="col-md-12">
            <RESEARCHInfo/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <DividentTracker/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
            <Marketsentiment/>
            </div>
          </div>
        </main>
        <footer></footer>
      </div>
    </>
  );
}

export default Portfolio;