import * as React from 'react';


function LandingSectionOne() {
    return (
        <div style={{ height: '550px', position: 'relative', width: '100%', marginTop: "90px" ,backgroundColor:"black" }}>
            <video className='videoTag' autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                <source src='/videos/landing.mp4' type='video/mp4' />
            </video>
            <div className="centered-text">
                <div className='p-5'></div>
                <div className='col-8 text-start container'>
                    <h1 class="landing-h1">Connecting Traders, Sharing Success</h1>
                </div>
            </div>
        </div>
    );
}

export default LandingSectionOne;