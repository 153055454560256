// components/Footer.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css'

const Footer = () => {
    return (
        <footer className="bg-black text-white py-4 ">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-12 ps-5 pb-5'>
                        <div>
                            <a href="/">
                                <img className="img-fluid" style={{ maxWidth: '150px' }} src="/images/SVAlogoWithName.png" alt="Logo" />
                            </a>
                            <p>By the Traders, For the Traders</p>
                        </div>
                        <div className='mt-4'>
                            <img src="images/twitter.svg" alt="CSE" className="img-fluid" width={'25px'} />
                        </div>
                        <div className='mt-4'>
                            <div className='d-felx'>
                                <div className='d-flex'>
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"  >
                                        <g id="Globe vector">
                                            <path d="M1 11C1 16.523 5.477 21 11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12 1.05005C12 1.05005 15 5.00005 15 11C15 17 12 20.9501 12 20.9501M10 20.9501C10 20.9501 7 17 7 11C7 5.00005 10 1.05005 10 1.05005M1.63 14.5H20.37M1.63 7.50005H20.37" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                    </svg>
                                    <div className='ms-3'> ENGLISH  </div>
                                    <div className="ms-3">
                                        <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0L5 5L10 0L0 0Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-12'>
                        <div className="footer-container container mb-5">
                            <div className="row justify-content-center">
                                <div className="col-lg-3 col-md-6 text-center link-colums">
                                    <div style={{ backgroundColor: '#FFFFFF40', borderRadius: '15px' }}><h3>Products</h3></div>
                                    <ul className="list-unstyled">
                                        <li><a href="/" className="text-white">Supercharts</a></li>
                                        <li><a href="/" className="text-white">Pine Script™</a></li>
                                        <li><a href="/" className="text-white">Stock Screener</a></li>
                                        <li><a href="/" className="text-white">ETF Screener</a></li>
                                        <li><a href="/" className="text-white">Forex Screener</a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 text-center link-colums">
                                    <div style={{ backgroundColor: '#FFFFFF40', borderRadius: '15px' }}><h3>Company</h3></div>
                                    <ul className="list-unstyled">
                                        <li><a href="/" className="text-white">About</a></li>
                                        <li><a href="/" className="text-white">Features</a></li>
                                        <li><a href="/" className="text-white">Pricing</a></li>
                                        <li><a href="/" className="text-white">Social Network</a></li>
                                        <li><a href="/" className="text-white">Wall of Love</a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 text-center link-colums">
                                    <div style={{ backgroundColor: '#FFFFFF40', borderRadius: '15px' }}><h3>Community</h3></div>
                                    <ul className="list-unstyled">
                                        <li><a href="/" className="text-white">Supercharts</a></li>
                                        <li><a href="/" className="text-white">Pine Script™</a></li>
                                        <li><a href="/" className="text-white">Stock Screener</a></li>
                                        <li><a href="/" className="text-white">ETF Screener</a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-md-6 text-center link-colums">
                                    <div style={{ backgroundColor: '#FFFFFF40', borderRadius: '15px' }}><h3>For business</h3></div>
                                    <ul className="list-unstyled">
                                        <li><a href="/" className="text-white">Forex Screener</a></li>
                                        <li><a href="/" className="text-white">Refer a friend</a></li>
                                        <li><a href="/" className="text-white">Ideas</a></li>
                                        <li><a href="/" className="text-white">Scripts</a></li>
                                        <li><a href="/" className="text-white">House rules</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="markets-copyright row justify-content-center align-items-center">
                    <div className="markets col-lg-7 col-md-12 mx-auto d-flex justify-content-center align-items-center">

                        <div className="marketers-img me-2"><a href="/"><img src="/images/marketers/app-desktop-badge-en.svg" alt="CSE" className="img-fluid" /></a></div>
                        <div className="marketers-img ms-2"><a href="/"><img src="/images/marketers/app-store-badge-en.svg" alt="CSE" className="img-fluid" /></a></div>
                        <div className="marketers-img me-2"><a href="/"><img src="/images/marketers/google-play-badge-en.svg" alt="CSE" className="img-fluid" /></a></div>
                        <div className="marketers-img ms-2"><a href="/"><img src="/images/marketers/trade-on-tradingview-badges-en.svg" alt="CSE" className="img-fluid" /></a></div>
                    </div>
                    <div className="copyright col-lg-5 col-md-12 text-lg-end text-center">
                        <div>
                            <p className='mb-0'>
                                Select market data provided by <a href="/" className="text-primary">CSE Data Services</a>
                            </p>
                            <span className="text-primary pt-0"> &copy;2024 stockWiseAnalytics</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
