import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import { EmailShareButton, TwitterShareButton } from "react-share";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { Base_URL } from "../../Components/BaseURL.js";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap-icons/font/bootstrap-icons.css";
// import fetchCurrentPrice from './fetchCurrentPrice'; // Correctly import the fetch function
import FinancialData from "./FinancialData.js";
import { stockData } from "./data";
import { Close } from '@material-ui/icons';
import AfterconfirmDialog from "./AfterConfirmDialog.js";
const EditConfirmdialog_add = ({ open, onClose, stockData, onUpdate }) => {
  const [confirmdialogafter, setConfirmdialogafter] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [isSocialMediaModalOpen, setIsSocialMediaModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const [formData, setFormData] = useState({
    InputOrdID: "",
    InputStock: "",
    InputQty: "",
    InputAvgPrice: "",
    InputCurrentPrice: "100",
    InputStockName: "",
    username: localStorage.getItem("unm"),
    InputNewQty: "",
    InputNewPQty: "",
    InputNewAvgPrice: "",
    InputNewPAvgPrice: "",
    Inputmax:"",
  });
 
  const handleSocialMediaMessage = (message) => {
    // Handle sending the social media message using the provided message
    console.log("Sending social media message:", message); // Replace with actual logic
    setIsSocialMediaModalOpen(false); // Close the modal after sending
  };
  useEffect(() => {
    if (stockData) {
      setFormData({
        InputOrdID: stockData.InputOrdID,
        InputStock: stockData.InputStock,
        InputQty: stockData.InputQty,
        InputAvgPrice: stockData.InputAvgPrice,
        InputCurrentPrice: stockData.InputCurrentPrice,
        InputStockName: stockData.InputStockName,
        username: localStorage.getItem("unm"),
        InputNewQty: stockData.InputNewQty,
        InputNewPQty: stockData.InputNewPQty,
        InputNewAvgPrice:stockData.InputNewAvgPrice,
        InputNewPAvgPrice: stockData.InputNewPAvgPrice,
        Inputmax:stockData.csemco_quantity,
      });
    }
  }, [stockData]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const res = await axios.post(
  //       Base_URL + `/portfolio/stockupdate`,
  //       formData
  //     );
  //      toast.success("Stock Data Successfully updated.");
  //      setIsSocialMediaModalOpen(true);
  //      onUpdate(formData); // Pass updated data to parent
  //     // //onClose(); // Close the modal
  //    // setConfirmdialogafter(true);
  //   } catch (error) {
  //     toast.error("Error submitting data. Please try again later.");
  //     console.error(error);
  //   }
  // };
  const handleCancelSocialMedia = () => {
    setIsSocialMediaModalOpen(false); // Close the modal
    window.location.reload();
  };

  const handleconfirmModal = async (formDataStocks) => {
    try {
      const res = await axios.post(
        Base_URL + `/portfolio/stockupdate`,
        formData
      );
       toast.success("Stock Data Successfully updated.");
       console.log('---formData----'+formData);
       setUpdateData(formData);
       setConfirmdialogafter(true);
    } catch (error) {
      toast.error("Error submitting data. Please try again later.");
      console.error(error);
    }
};

const closeDialog_afterconfirm = () => {
  setConfirmdialogafter(false);
  setUpdateData(null);
   window.location.reload();
};




  return (
<>
 <div>
      <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
    >
                  <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">Confirmation adding the record</Typography>
        <div className="col-auto font-family-Montserrat text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}>
        <Close/>
        </IconButton></div>
      </DialogTitle>
           <DialogContent dividers>
        <ToastContainer />
        <form 
        // onSubmit={handleSubmit} 
        class="form-inline">
          <input type="hidden" name="InputOrdID" id="InputOrdID"  value={formData.InputOrdID}></input>
          <input type="hidden" name="InputStock" id="InputStock" value={formData.InputStock}></input>
          <input type="hidden" name="Inputmax" id="Inputmax" value={formData.InputQty}/>
          <div className="row g-3 align-items-center">
          <div class="form-group row p-1">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-Montserrat">Stock</p>
            </div>
            <div className="col-7">
            <input type=""  
                    value={formData.InputStockName}
                   className="text-dark fw-light w-100 float-start text-start"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputStockName"
               name="InputStockName"
                    class="form-control text-start" 
                    disabled/> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-Montserrat">Current Quantity</p>
            </div>
            <div className="col-7">
            <input type=""  
                   value={formData.InputQty}
                   className="text-dark fw-light w-100 float-start text-end"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputQty"
               name="InputQty"
                    class="form-control text-end" 
                    disabled/> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-Montserrat text-nowrap">Current Avg. Price Purchased</p>
            </div>
            <div className="col-7">
            <input type=""  
                   value={formData.InputAvgPrice}
                   className="text-dark fw-light w-100 float-start text-end"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputAvgPrice"
               name="InputAvgPrice"
                    class="form-control text-end" 
                    disabled/> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-Montserrat text-nowrap">New Purchased Quantity</p>
            </div>
            <div className="col-7">
            <input type=""  
                   value={formData.InputNewQty}
                   className="text-dark fw-light w-100 float-start text-end"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputNewQty"
               name="InputNewQty"
                    class="form-control text-end" 
                    disabled/> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-Montserrat text-nowrap">New Purchased Price</p>
            </div>
            <div className="col-7">
            <input type=""  
                   value={formData.InputNewAvgPrice}
                   className="text-dark fw-light w-100 float-start text-end"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputNewAvgPrice"
               name="InputNewAvgPrice"
                    class="form-control text-end" 
                    disabled/> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-Montserrat text-nowrap">New Position Quantity</p>
            </div>
            <div className="col-7">
            <input type=""  
                   value={formData.InputNewPQty}
                   className="text-dark fw-light w-100 float-start text-end"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputNewPQty"
               name="InputNewPQty"
                    class="form-control text-end" 
                    disabled/> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-Montserrat text-nowrap">New Position Avg. Price</p>
            </div>
            <div className="col-7">
            <input type=""  
                    value={formData.InputNewPAvgPrice}
                   className="text-dark fw-light w-100 float-start text-end"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputNewPAvgPrice"
               name="InputNewPAvgPrice"
                    class="form-control text-end" 
                    disabled/> 
              
            </div>
            </div>
         
          </div>
          <div className="p-4"></div>
          <div className="col-auto text-end"> 
          <button type="button" className="btn btn-dark border border-white mr-1" onClick={onClose}>
          Close</button>  
          {!isSocialMediaModalOpen && (        
            <button type="button" onClick={() => handleconfirmModal(formData)} className="btn btn-dark border border-white mr-1">Save Changes</button>
          )}
           </div>
        </form>
      </DialogContent>
    </Dialog>
<AfterconfirmDialog
        open={confirmdialogafter}
        onClose={closeDialog_afterconfirm}
         stockData={updateData}
      />

    </div>
    </>
  );
};

export default EditConfirmdialog_add;
