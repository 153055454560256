// export default FinancialInfo;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import "bootstrap-icons/font/bootstrap-icons.css";
import fetchCPrice from './fetchCurrentPrice';

const FinancialInfo = ({ initialUserData }) => {
    const [fininfor, setFininfor] = useState([]);
    const [matchingData, setMatchingData] = useState({});

    const username = localStorage.getItem('unm');
    const symbols = localStorage.getItem('symbolstring');
    const localLogoImagePath = "/images/companylogos";
    
    let inputString = symbols.replace(/[() ]/g, '');
    let arrayOfStrings = inputString.split(',');
    arrayOfStrings = arrayOfStrings.map(str => `'${str}'`);
    let resultString = arrayOfStrings.join(',');

    useEffect(() => {
        const fetchFiniData = async () => {
            try {
                const res = await axios.get(Base_URL + "/fininfo/getfininfo", {
                    params: { username: username, symbols: resultString }
                });
                setFininfor(res.data);
                
                // Fetch current prices for each stock
                const pricesPromises = res.data.map(async (fininf) => {
                    try {
                        const price = await fetchCPrice(fininf.stock_name);
                        return { [fininf.stock_name]: price };
                    } catch (error) {
                        console.error(`Error fetching price for ${fininf.stock_name}:`, error);
                        return { [fininf.stock_name]: null };
                    }
                });

                // Resolve all promises and update state with matchingData
                const pricesData = await Promise.all(pricesPromises);
                const pricesObject = pricesData.reduce((acc, curr) => ({ ...acc, ...curr }), {});
                setMatchingData(pricesObject);

            } catch (error) {
                console.error(error);
                toast.error("Failed to fetch data");
            }
        };

        fetchFiniData();
    }, [username, resultString]);

    return (
        <>
            <p className="text-white fs-6 fw-bold font-family-Montserrat">
                FINANCIAL INFORMATION
            </p>
            <div id="component" className="container-fluid py-3" style={{ backgroundColor: 'black', width: '100%', height: '300px', overflow: 'auto' }}>
                <table id="example" className="table table-striped table-dark font-family-Montserrat mytable">
                    <thead>
                        <tr className="border-bottom border-primary-subtle border-opacity-25">
                            <th>Stock Ticker Symbol</th>
                            <th>Market Capitalization (1B LKR)</th>
                            <th>Earnings Per Share (LKR)</th>
                            <th>Net Asset Value (LKR)</th>
                            <th>Price-to-Earnings Ratio</th>
                            <th>Price-to-Book Ratio</th>
                            <th>Dividend Yield</th>
                            <th>Top Shareholders</th>
                            <th>Share Ownership</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fininfor.length === 0 ? (
                            <tr>
                                <td colSpan="9">No data available</td>
                            </tr>
                        ) : (
                            fininfor.map(fininf => (
                                <tr key={fininf.id} className="border-bottom border-secondary border-opacity-25">
                                    <td>
                                        <img
                                            src={fininf.stock_name ? `${localLogoImagePath}/${fininf.stock_name}.png` : 'images/companylogos/default-logo.png'}
                                            width="30px" height="auto"
                                            alt={fininf.stock_name}
                                        />
                                        {fininf.stock_name}
                                    </td>
                                    <td>{fininf.market_capitalization}</td>
                                    <td>{fininf.earning_per_share}</td>
                                    <td>{fininf.net_asset_values}</td>
                                    <td>{matchingData[fininf.stock_name] ? (fininf.earning_per_share / matchingData[fininf.stock_name]).toFixed(2) : 'N/A'}</td>
                                    <td>{matchingData[fininf.stock_name] ? (fininf.net_asset_values / matchingData[fininf.stock_name]).toFixed(2) : 'N/A'}</td>
                                    <td>{matchingData[fininf.stock_name] ? (fininf.dividend_per_share / matchingData[fininf.stock_name]).toFixed(2) : 'N/A'}</td>
                                    <td>{fininf.top_shareholders}</td>
                                    <td>{fininf.share_ownership}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default FinancialInfo;
