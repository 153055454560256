import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from '../../Components/BaseURL.js';
const username =localStorage.getItem('unm');
const Country = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
       try {

        axios({
          method: 'get', // or get, depending on your request type
          url: `${Base_URL}/masterdata/country`,
                   withCredentials: true // make sure this is set if you are using cookies
      })
      .then(response => console.log(response))
      .catch(error => console.log(error));     
      } catch (error) {
        console.error(error);
        toast.error("Failed to load data");
      } finally {
 
      }
    };
    fetchData();
  }, []);


  return (
    <>
      <div className="col-md-6 bg-light bg-black text-white w-100">
        <ToastContainer />
                <legend>
            <span className="label label-default">
              <b>PUBLIC DETAILS</b>
            </span>
                 </legend>
            <div className="row row-cols-12 pt-3 form-inline">
            <div className="col text-start col-3">
              <label className="text-nowrap">Country</label>
            </div>
            <div className="col-6">
              <select
                className="form-select text-white bg-black fw-light"
                    name="InputCountry"
           
              >
                <option value="">Select a country</option>
                {Array.isArray(data) && data.map((country) => (
                  <option key={country.csem_id} value={country.csem_country_code}>
                    {country.csem_country_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
      </div>
    </>
  );
};

export default Country;
