//  import { Outlet, useLocation } from "react-router-dom";
//  import Header from '../UI-components/layout/common/header/Header.js';
//  import Footer from '../UI-components/layout/common/footer/Footer.js'; // Assuming you have a Footer component
//  import React from 'react';
//  import './Layout.css';  // Import specific CSS for layout
// const Layout = () => {
//   const location = useLocation();
//   return (
//      <React.Fragment>
// {location.pathname !== '/login' && <Header />}
//       <main>
//         <Outlet />
//       </main>
// {location.pathname !== '/login' && <Footer />}
//     </React.Fragment>  
//   )
// };

// export default Layout;

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../UI-components/layout/common/header/Header';
import Footer from '../UI-components/layout/common/footer/Footer';
import './Layout.css'; // Import specific CSS for layout

const Layout = () => {
  const location = useLocation();

  return (
    <div className="layout">
      {location.pathname !== '/login' && location.pathname !== '/userregister' && <Header />}
      <main className="content bg-black">
        <Outlet />
      </main>
      {location.pathname !== '/login' && location.pathname !== '/userregister' && <Footer />}
    </div>
  );
};

export default Layout;